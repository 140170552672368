<ng-container *ngIf="showTimeline; else empty">
  <div *ngFor="let year of yearsToDisplay; let index = index; let firstYear = first; let lastYear = last; trackBy: trackByName"
    @yearAddedRemoved class="year-content">
    <div class="year-header">{{ year.name }}</div>
    <div class="quarter-content" [ngClass]="{ 'first-year': firstYear, 'last-year': lastYear }">
      <div class="flex">
        <div *ngFor="let quarter of year.quarters; trackBy: trackByName" class="quarter-header">
          {{ quarter.name }}
        </div>
      </div>
      <div class="quarter-cells">
        <div *ngFor="let quarter of year.quarters; trackBy: trackByName" class="quarter-cell">
          <div class="plan-bar" [ngClass]="{ 'plan-bar--start': quarter.planStart, 'plan-bar--end': quarter.planEnd }">
            <div [ngClass]="{ 'plan-active': quarter.planActive, 'plan-active--secondary': showReporting || fundingSources?.length }">
              <div *ngIf="quarter.planStart" class="plan-label">Plan</div>&nbsp;
            </div>
            <div [attr.title]="quarter.title" *ngIf="!!quarter.title && (!fundingSources.length || showPlanReportingOnly)"
              @reportAddedRemoved class="report-icon"
              [ngClass]="{ 'final-report': quarter.showFinalReport }">
              <icon iconId="fiscal-reporting"></icon>
            </div>
          </div>
          <div *ngFor="let fund of quarter.fundBars; trackBy: trackById" class="plan-bar plan-bar--funding-source" [ngClass]="{ 'plan-bar--start': fund.start, 'plan-bar--end': fund.end }">
            <div [ngClass]="{ 'plan-active': fund.active }">
              <div *ngIf="fund.start" class="plan-label">{{fund.name}}</div>&nbsp;
            </div>
            <div [attr.title]="fund.title" *ngIf="!!fund.title && !showPlanReportingOnly" @reportAddedRemoved class="report-icon" [ngClass]="{ 'final-report': fund.showFinalReport }">
              <icon iconId="fiscal-reporting"></icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #empty>
  <div class="empty-state">
    Set Start Year, Start Quarter, and Spending Duration <ng-container *ngIf="!inDetailsTab">in Details tab </ng-container>to see Plan Timeline.
  </div>
</ng-template>