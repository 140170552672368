import { Model } from '@app-ngrx-domains';
import { Proposal } from './proposal';

export interface ISEPProposal extends Model.ProposalBase {
  // v1
  year_string: string;
  read_legislation: boolean;
  process_schedule: string;
  success_criteria: string;
  executive_summary: string;
  population_groups: Array<Model.EAPopulationGroup>;
  activity_items: Array<Model.EAActivityItem>;
  mid_allocation_report: Model.EAMidAllocationReport;

  // v2
  prior_plan: { year_string: string, activity_items: Array<Model.EAActivityItem> },
  assurance_ids: Array<Model.AttributeValue>;
  race_consciousness: string;
  key_initiatives: string;
  decreased_impact_evidence: string;
  planning_efforts: string;
  pandemic_interrupted: string;
  pandemic_catalyzed: string;
  pandemic_delayed: string;

  object_code_expenditures: Array<Model.EAObjectCodeExpenditures>;
  activity_spending_reports: Array<Model.EAActivitySpendingReports>;

  student_populations: Array<Model.EAStudentPopulations>;
  student_population_plan: Array<Model.EAStudentPopulationPlan>;

  survey_responses: Array<Model.EASurveyResponse>;
}

export interface ISEPDashboardRow {
  proposal_id: number;
  title: string;
  duration_id: number;
  year_string: string;
  current_status: string;
  institution: Model.Institution;
}

export class SEPProposal {

  static getDemographicName(population_group: Model.EAPopulationGroup) {
    return population_group.custom_demographic ||
      population_group && population_group.impacted_group
      && population_group.impacted_group.name || 'Unknown Demographic';
  }

  static getGenderName(population_group: Model.EAPopulationGroup) {
    return population_group && population_group.impacted_group_gender
      && population_group.impacted_group_gender.name || 'Unkonwn Gender';
  }

  static getMetricName(population_group: Model.EAPopulationGroup) {
    return population_group && population_group.metric_definition
      && population_group.metric_definition.name || 'Unkown Metric';
  }
}

export interface ISEPDistrictProposal extends Model.ProposalBase {
  allocation_correct: boolean;
  allocation_explanation: string;
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type SEPProposal = ISEPProposal;
    export type SEPDashboardRow = ISEPDashboardRow & ISEPProposal;
    export type SEPDistrictProposal = ISEPDistrictProposal;
  }
}
