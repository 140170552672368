import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Model } from '@app-ngrx-domains';
import { FileUploader } from 'ng2-file-upload';
import { EMPTY, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FILE_UPLOAD_TYPES } from '../consts/';
import { AppUtils } from '../utilities';
import { Profile } from '../models/user';
import { map } from 'rxjs/operators';

@Injectable()
export class ApiService {
  private apiVersion = 'v1';
  private hostname: string;

  constructor(private http: HttpClient) {
    this.hostname = environment.apiHostUrl;
  }

  private urlPrefix(version?: string): string {
    return `${this.hostname}/${version ? version : this.apiVersion}`;
  }

  private buildFilterParams(filter: Object, includeFilterName: boolean = true): HttpParams {
    return Object.entries(filter)
      .filter(([_, value]) => {
        const hasValue = typeof value !== 'undefined' && value !== null;

        return Array.isArray(value)
          ? value.length && hasValue
          : hasValue;
      })
      .reduce((paramMap, [key, value]) => {
        const paramName = includeFilterName ? `filter[${key}]` : key;
        if (Array.isArray(value)) {
          paramMap = paramMap.append(paramName, value.join(','));
        } else {
          paramMap = paramMap.append(paramName, String(value));
        }

        return paramMap;
      }, new HttpParams())
  }

  get(url: string, options?: any, version?: string): Observable<any> {
    return this.http.get(this.urlPrefix(version) + url, options)
  }

  getAsText(url: string, version?: string): Observable<any> {
    return this.http.get(this.urlPrefix(version) + url, { responseType: 'text' });
  }

  post(url: string, body?: any, contentType: string = 'application/json', version?: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', contentType);
    return this.http.post(this.urlPrefix(version) + url, body, { headers: headers });
  }

  put(url: string, body?: any, version?: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.urlPrefix(version) + url, body, { headers });
  }

  delete(url: string, body?: any, version?: string): Observable<any> {
    if (body) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: body
      };
      return this.http.delete(this.urlPrefix(version) + url, httpOptions);
    } else {
      return this.http.delete(this.urlPrefix(version) + url);
    }
  }

  healthCheck(): Observable<any> {
    return this.http.get(this.hostname + '/VERSION.txt', { responseType: 'text' });
  }

  /**
   * Detects if there is a cached, valid token (well-formed but not necessarily authenticated), or returns false
   * A token might not exist, or might not be well-formed.
   * @return {string|boolean} decoded token if it exists, otherwise false
   */
  hasAuthToken() {
    const basicUser = AppUtils.jwtDecodeToken(localStorage.getItem('auth_token') || ''); // Decodes either to valid basic user record, or undefined.
    return basicUser ? basicUser : false;
  }


  /* FILE UPLOADS **************************************/

  /** Instantiates an instance of a ng2-file-upload's FileUploader for uploading files.
   * @param type {string} One of the FILE_UPLOAD_TYPES values
   * @param options {any} Provides properties appropriate for the given type.
   * @param options.institution_id
   * @param options.lmi_id
   * @returns {FileUploader}
   */
  fileUploader(type: string, options?: any): FileUploader {
    let url;
    let autoUpload = false; // Begin upload immediately after selecting file in file chooser?
    switch (type) {
      case FILE_UPLOAD_TYPES.LMI:
        url = this.urlPrefix() + '/lmis/' + options.lmi_id.toString() + '/evidences';
        autoUpload = true;
        break;
      case FILE_UPLOAD_TYPES.INSTITUTION_LOGO:
        url = this.urlPrefix() + '/institutions/' + options.institution_id.toString() + '/logo';
        autoUpload = true;
        break;
      case FILE_UPLOAD_TYPES.CORE_INDICATORS:
        url = this.urlPrefix() + '/perkins/upload/core_indicators';
        break;
      case FILE_UPLOAD_TYPES.HEADCOUNT:
        url = this.urlPrefix() + '/perkins/upload/headcount';
        break;
      default:
        url = this.urlPrefix() + '/files';
        break;
    }
    return new FileUploader({
      url: url,
      autoUpload: autoUpload,
      removeAfterUpload: true,
      authToken: localStorage.getItem('auth_token'),
      authTokenHeader: 'x-nova-auth-token',
      headers: environment.apiKey
        ? [{name: 'x-api-key', value: environment.apiKey}]
        : []
      // 'headers': TODO - We could copy over all of this.options().headers for completeness, but it's not necessary at this moment.
    });
  }

  /** Bookmarks **************************************************/
  getBookmarks(
    filter: {
      fund_id?: number,
      proposal_id?: number,
      institution_id?: number,
      duration_id?: number,
      area_name?: string
    } = {}
  ) {
    const params = this.buildFilterParams(filter);

    return this.get('/bookmarks', { params });
  }

  /** Proposals **************************************************/
  listReportingProposals(fund_ids: any, duration_id: number, institution_ids: any) {
    let queryParam = 'fund_ids=[' + fund_ids + ']&duration_id=' + duration_id;
    queryParam = queryParam + '&institution_ids=[' + institution_ids + ']';
    return this.get('/proposals/reporting?' + queryParam);
  }

  createProposal(body: any) {
    return this.post('/proposals/', body);
  }

  upsertProposalInstitution(proposal_id: number, institution_id: number, body: any) {
    return this.put(`/proposals/${proposal_id}/institutions/${institution_id}`, body);
  }

  removeProposalInstitution(proposal_id: number, institution_id: number) {
    return this.delete(`/proposals/${proposal_id}/institutions/${institution_id}`);
  }

  removeProposalAgency(proposal_id: number, institution_id: number, body: any) {
    return this.post(`/proposals/${proposal_id}/institutions/${institution_id}/removeAgency`, body);
  }

  getProposals(filter: any, editableOnly: boolean = false) {
    const filters = {
      ...filter,
      editable: editableOnly
    };

    if (filter.fund_id) {
      filters.fund_ids = [filter.fund_id];
      delete filters.fund_id;
    }

    const params = new HttpParams({ fromObject: filters });
    return this.get('/proposals', { params });
  }

  getProposalById(id: number) {
    return this.get('/proposals/' + id);
  }

  getSimpleProposalById(id: number) {
    return this.get(`/proposals/${id}?simple_fetch=true`);
  }

  listProposalArchives(proposalId: number) {
    return this.get(`/proposals/${proposalId}/archives`);
  }

  getArchivedProposal(proposalId: number, archiveId?: number) {
    const params = archiveId ? { params: { archiveId } } : undefined;
    return this.get(`/proposals/${proposalId}/archive`, params);
  }

  deleteProposal(id: number) {
    return this.delete('/proposals/' + id);
  }

  listProposalReviews(fund_id: number, params: any) {
    return this.get(`/proposals/reviews/${fund_id}`, { params });
  }

  // TODO: We need a model
  getProposalReviews(proposal_id: number): Observable<any> {
    return this.get('/proposal_reviews', { params: { proposal_id } });
  }

  runProposalReport(reportName: string, options: Object = {}) {
    let params = this.buildFilterParams(options, false);
    params = params.append('report_name', reportName);

    return this.get('/proposals/report', { params });
  }

  listProposalFunding(fund_id: number, options: any) {
    const params = this.buildFilterParams(options, false);
    return this.get(`/proposals/funding/${fund_id}`, { params });
  }

  listProposalRenewableFunding(fund_id: number, params: any) {
    return this.get(`/proposals/renewal/${fund_id}`, { params });
  }

  listProposalFundingDeferral(fund_id: number, params: any) {
    return this.get(`/proposals/deferral/${fund_id}`, { params });
  }

  deferPropposalFunding(proposal_id: number, body: any) {
    return this.post(`/proposals/${proposal_id}/deferFunding`, body);
  }

  sendBatchedFundingOffers(fund_id: number) {
    return this.post(`/proposals/funding/${fund_id}/offer`);
  }

  allSectorPlanInit(id: number) {
    return this.post(`/proposals/${id}/crosscut`);
  }

  allSectorPlanDestroy(id: number) {
    return this.delete(`/proposals/${id}/crosscut`);
  }

  getProposalSigningAuthorities(id: number) {
    return this.get(`/proposals/${id}/signing`);
  }

  getProposalMinimumPlanLength(proposal_id: number) {
    return this.get(`/proposals/${proposal_id}/minimumPlanLength`);
  }

  continuePlan(proposal_id: number, body: any) {
    return this.post(`/proposals/${proposal_id}/continuePlan`, body);
  }

  /** Emails **************************************************/
  sendHelpDeskEmail(options: any) {
    return this.post('/contact-us', options);
  }

  emailDocument(recipient_user_ids: Array<number>, extraData?: any, types?: Array<string>) {
    return this.post('/messages/share', {
      ...(extraData || {}),
      recipient_user_ids,
      types,
    });
  }

  /** LMI ***************************************************/

  getLmi(id: number) {
    return this.get('/lmis/' + id);
  }

  createLmi(id: number, body) {
    return this.post('/proposals/' + id + '/lmis', body);
  }

  updateLmi(id: number, body: any) {
    return this.put('/lmis/' + id, body);
  }

  deleteLmi(id: number, body: any) {
    return this.delete('/lmis/' + id);
  }

  deleteLmiSocCode(lmi_id: number, soc_code_id: number) {
    return this.delete('/lmis/' + lmi_id + '/soc_codes/' + soc_code_id);
  }

  addSocCode(id: number, body: any) {
    return this.post('/lmis/' + id + '/soc_codes', body);
  }

  // General codes retrieval
  getCodes(type: string) {
    return this.get('/lookups/' + type);
  }

  getObjectCodes() {
    return this.get('/object_codes');
  }

  getLegislation() {
    return this.get('/legislation');
  }

  getStates() { // Proposal state (creation, review,etc), NOT the USA state
    return this.get('/states');
  }

  addSubregionToLmi(lmi_id: number, body: any) {
    return this.post('/lmis/' + lmi_id + '/subregions', body);
  }

  delSubregionFromLmi(lmi_id: number, institution_id: number) {
    return this.delete('/lmis/' + lmi_id + '/subregions/' + institution_id);
  }

  addCountyToLmi(lmi_id: number, body: any) {
    return this.post('/lmis/' + lmi_id + '/counties', body);
  }

  delCountyFromLmi(lmi_id: number, institution_id: number) {
    return this.delete('/lmis/' + lmi_id + '/counties/' + institution_id);
  }

  createProgram(lmi_id: number, body) {
    return this.post('/lmis/' + lmi_id + '/programs', body);
  }

  updateDemand(demand_id: number, demand_amount: number) {
    return this.put('/lmis/demand/' + demand_id, { demand_amount });
  }

  updateOccupationalProjections(id: number, has_projections: number) {
    return this.put('/lmis/' + id + '/has-occupational-projections/' + has_projections, undefined);
  }

  updateProgram(lmiId: number, programId: number, body: any) {
    return this.put(`/lmis/${lmiId}/programs/${programId}`, body);
  }

  deleteProgram(lmiId: number, programId: number) {
    return this.delete(`/lmis/${lmiId}/programs/${programId}`);
  }

  /** Impacted Groups ************************************************/
  getImpactedGroups() {
    return this.get('/impacted_groups');
  }

  /** Institutions ***************************************************/
  getInstitutionById(institution_id: number) {
    return this.get(`/institutions/${institution_id}`);
  }


  createInstitution(institution: any) {
    return this.post('/institutions/', institution);
  }

  deleteInstitution(institution: any) {
    return this.delete('/institutions/' + institution.id);
  }

  updateInstitution(institution: any) {
    return this.put('/institutions/' + institution.id, institution);
  }

  rejectInstitution(institutionId: number, comments?: string) {
    return this.post(`/institutions/${institutionId}/reject`, { comments });
  }

  getRecommendations() {
    return this.get('/recommendations/');
  }

  getLineage(institutionId: number) {
    return this.get(`/institutions/${institutionId}/lineage`);
  }

  getSwpHierarchy(institution_id: number) {
    return this.get(`/institutions/${institution_id}/swp_hierarchy`);
  }

  getCountiesAndCitites() {
    return this.get('/institutions/counties_cities');
  }

  /**
   * List institutions
   * @param filter An object of filter conditions used directly in the SQL `where` clause
   * @param options parameters accepted by the Service
   * @return {Observable<Array<Model.Institution>>}
   */
  listInstitutions(
    options?: {
      type?: string,
      types?: Array<string>,
      institution_ids?: Array<number>,
      region_ids?: Array<number>,
      ccc?: boolean,
      caep?: boolean,
      match_strings?: string,
      include_hierarchy?: boolean,
      include_parent?: boolean,
      include_settings?: boolean,
      limit?: number,
      name?: string
    }) {
    let queryString = '';

    Object.keys(options || {}).forEach(optionKey => {
      queryString += `&${optionKey}=${options[optionKey]}`;
    });

    // Replace the first '&' to '?' to form a valid query
    if (queryString.length) {
      queryString = queryString.replace(/^./, '?');
    }

    return this.get(`/institutions${queryString}`);
  }

  /** Workplans ***************************************************/
  createWorkplanItem(body) {
    return this.post('/workplans', body);
  }

  updateWorkplan(id: number, body: any) {
    return this.put('/workplans/' + id, body);
  }

  deleteWorkplanItem(id: string) {
    return this.delete('/workplans/' + id);
  }

  addWorkplanCollege(id, institution_id, is_lead?: boolean) {
    return this.post('/workplans/' + id + '/institutions/', { institution_id, is_lead });
  }

  deleteWorkplanCollege(id, institution_id) {
    return this.delete('/workplans/' + id + '/institutions', { institution_id });
  }

  updateWorkplanStrategy(id, strategy_id) {
    return this.put(`/workplans/${id}/strategies/${strategy_id}`);
  }

  deleteWorkplanStrategy(id, strategy_id) {
    return this.delete(`/workplans/${id}/strategies/${strategy_id}`);
  }

  /** Sectors ****************************************************/
  getSectors() {
    return this.get('/sectors/');
  }

  /** CONTACTS ***************************************************/
  listContacts(matchStrings, limit) {
    let queryString = '';

    if (matchStrings) {
      queryString = `?matchStrings=${matchStrings}`;
    }

    if (limit) {
      queryString = (queryString.length > 0)
        ? `${queryString}&limit=${limit}`
        : `?limit=${limit}`;
    }

    return this.get(`/contacts${queryString}`);
  }

  /** Budgets ****************************************************/
  addBudgetItem(budget_item) {
    return this.post('/budgetItem', budget_item);
  }

  updateBudgetItem(budget_item) {
    return this.put('/budgetItem', budget_item);
  }

  deleteBudgetItem(budget_item_id: number) {
    return this.delete('/budgetItem/' + budget_item_id);
  }

  getBudgetAmounts(institution_ids: number[], fund_ids: number[], duration_ids: number[]) {
    return this.get('/budgets', {
      params: {
        institution_ids: institution_ids.join(','),
        fund_ids: fund_ids.join(','),
        duration_ids: duration_ids.join(',')
      }
    });
  }

  /** Evidence ***************************************************/
  getEvidences(lmi_id: number) {
    return this.get('/lmis/' + lmi_id + '/evidences');
  }

  deleteEvidence(evidenceId: number) {
    return this.delete('/lmis/evidences/' + evidenceId.toString());
  }

  /** Metrics ***************************************************/
  upsertMetric(body: any) {
    return this.put('/metrics/', body);
  }

  deleteMetric(metric: any) {
    return this.delete('/metrics/', metric);
  }

  getMetricsDefinitions() {
    return this.get('/metric_definitions');
  }

  /** Launchboard Metrics */
  getGPMetrics(institution_id: number) {
    return this.get('/lb/' + institution_id + '/metrics');
  }

  getLVGMetrics(institution_id: number) {
    return this.get('/lb/' + institution_id + '/successMetrics');
  }

  getSEPMetrics(institution_id: number) {
    return this.get(`/metrics/studentEquity/${institution_id}`);
  }

  getSWPMetrics(institution_ids: Array<number>, duration_ids?: Array<number>, metric_definition_ids?: Array<number>) {
    let params = new HttpParams();
    Object.entries({ institution_ids, duration_ids, metric_definition_ids}).forEach(([key, value]) => {
      if (value && Array.isArray(value)) {
        params = params.append(key, value.join(','));
      }
    });

    return this.get('/metrics/swp', { params });
  }

  getLBMetrics(institution_ids: Array<number>, duration_ids?: Array<number>, metric_definition_ids?: Array<number>) {
    let params = new HttpParams();
    Object.entries({ institution_ids, duration_ids, metric_definition_ids}).forEach(([key, value]) => {
      if (value && Array.isArray(value)) {
        params = params.append(key, value.join(','));
      }
    });

    return this.get('/metrics/lb', { params });
  }

  getCAEPMetrics(institution_ids: Array<number>, duration_ids?: Array<number>, metric_definition_ids?: Array<number>) {
    let params = new HttpParams();
    Object.entries({ institution_ids, duration_ids, metric_definition_ids }).forEach(([key, value]) => {
      if (value && Array.isArray(value)) {
        params = params.append(key, value.join(','));
      }
    });

    return this.get('/metrics/caep', { params });
  }

  getCAEPLocaleInstitutionNames(institution_ids: Array<number>) {
    const params = { institution_ids };
    return this.get(`/metrics/caep_locales`, { params });
  }

  /** Users/profiles & Admin & Permissions  **************************************************/
  /* When allUsers is true and filterOptions is empty, the entire list of users will be returned. */
  listProfiles(filterOptions?: object, allUsers?: boolean, ignoreFundHierarchy?: boolean, includeScopes?: boolean): Observable<any> {
    let params = new HttpParams();
    if (filterOptions) {
      Object.entries(filterOptions).forEach(([key, value]) => {
        if (!(value instanceof Array)) {
          params = params.append(key, value);
        } else if (value.length) {
          params = params.append(key, value.join(','));
        }
      });
    }
    Object.entries({
      ignore_fund_hierarchy: ignoreFundHierarchy,
      all_users: allUsers,
      include_scopes: includeScopes
    }).forEach(([key, value]) => {
      if (value) {
        params = params.append(key, 'true');
      }
    });

    return this.get('/profiles', { params });
  }

  getSignupTokens(key?: string) {
    const params = new HttpParams();
    if (key) {
      params.append('key', key);
    }
    return this.get('/signupContext', { params });
  }

  getSignupContext(token) {
    return this.get(`/signupContext/${token}`);
  }

  signupUser(profile: any, context?: Object) {
    return this.post('/profiles', { profile, context, viaTask: true });
  }

  inviteUser(profile, fundKey?: string, proposalId?: number, institutionId?: number, roleName?: string) {
    const options = {
      fundKey,
      proposalId,
      institutionId,
      roleName,
      viaTasK: true
    };

    return this.post('/profiles', {
      profile,
      ...options
    });
  }

  deleteProfileById(id: number) {
    return this.delete(`/profiles/${id}`);
  }

  restoreProfileById(id: number) {
    return this.put(`/profiles/${id}/restore`);
  }

  updateProfile(profile: any) {
    return this.put(`/profiles/${profile.id}`, profile);
  }

  getProfileById(id: number) {
    return id ? this.get(`/profiles/${id}`) : EMPTY;
  }

  getRolesAndPermissions(filter: any = {}, permissions: boolean = true) {
    const params = new HttpParams({ fromObject: { ...filter, permissions } });
    return this.get('/roles', { params });
  }

  addUserRoleScope(role_scope: Model.UserRoleScope, notify?: boolean, override_subject?: string) {
    return this.post(`/users/${role_scope.user_id}/roles/`, {
      role_scope,
      notify,
      override_subject
    });
  }

  updateUserRoleScope(role_scope_id: number, user_role: any) {
    return this.put(`/users/${user_role.user_id}/roles/${role_scope_id}`, user_role);
  }

  removeUserRoleScope(user_role: any) {
    return this.delete(`/users/${user_role.user_id}/roles/`, user_role);
  }

  can(resources) {
    return this.post('/can', resources);
  }

  getSigningAuthorities(institution_id: number) {
    return this.get(`/institutions/${institution_id}/signing`);
  }

  getAuthorizationCode(params) {
    return this.post('/oauth/authorize', params, 'application/x-www-form-urlencoded');
  }

  /** If email/password credentials are provided, the API authenticates and returns a valid authtoken.
   * If credentials are not provided, the API will authenticate the existing cached authtoken,
   * which is sent on the POST header.
   */
  doLogin(email?: string, password?: string): Observable<any> {
    if (email && email.length && password && password.length) {
      return this.post('/auth', { credentials: { email, password } });
    } else {
      return this.post('/auth');
    }
  }

  passwordReset(email: string) {
    return this.get('/passwordReset/' + email);
  }

  updatePassword(credential) {
    return this.put('/profiles/password', { credentials: credential });
  }

  get_looker_url(looker_object: string) {
    return this.post('/auth/looker', { looker_object: looker_object });
  }

  sudoLoginUser(user_id: number) {
    return this.get('/auth/admin/sudo?response=token&user_id=' + user_id);
  }

  oAuthGoogle(): Observable<{ url: string }> {
    return this.get('/oauth/google_auth');
  }

  /** Entities/Consortia **************************************************/
  getConsortia(
    filter: {
      institution_id?: number,
      proposal_id?: number,
      duration_id?: number,
      status_id?: number,
      direct_funding?: boolean
    } = {}
  ): Observable<Array<Model.Cfad>> {
    const params = this.buildFilterParams(filter);

    return this.get('/cfads', { params });
  }

  /** Allocations **************************************************/
  getAllocations(
    filter: {
      direction?: string,
      institution_id?: number,
      fund_id?: number,
      fund_ids?: number[],
      from_fund_id?: number,
      from_fund_ids?: number[],
      duration_id?: number,
      proposal_id?: number,
      noProposal?: boolean
    } = {}): Observable<Array<Model.Allocation>> {
    const params = filter;

    if (params.noProposal) {
      delete params.proposal_id;
    }

    return this.get('/allocations', { params });
  }

  getRemainingAllocations(filter: any ): Observable<Array<{
    institution_id: number,
    duration_id: number,
    expenditureTotal: number,
    allocationTotal: number,
    remainingAllocation: number
  }>> {
    return this.get('/remainingAllocation', { params: filter });
  }

  getAvailableFunding(proposalId: number) {
    return this.get(`/proposals/${proposalId}/availableFunding`);
  }

  getBudgetLimits(filter: any) {
    return this.get('/budgetLimit', { params: filter });
  }

  getTransferLimits(fundId: number, fromPlans: boolean = false) {
    return this.get('/allocations/transfer/amount_available', { params: { fund_id: fundId, from_plans: fromPlans } });
  }

  submitAllocationTransfer(transferPayload: {
    description: string,
    from: string,
    to: string,
    transferAmount: number,
    allocations: Array<Model.Allocation>
  }) {
    return this.post('/allocations/transfer', transferPayload);
  }

  getTransferHistory(fundId: number) {
    return this.get('/allocations/transfer/' + fundId);
  }

  createAllocation(allocation: any) {
    return this.post('/allocations', allocation);
  }

  amendAllocations(allocations, fund_key) {
    const url = `/allocations/amendments?fund_key=${fund_key}`;
    return this.post(url, allocations);
  }

  deleteAllocation(allocation: any) {
    return this.delete('/allocations/' + allocation.id);
  }

  updateAllocation(allocation: any) {
    return this.put('/allocations/' + allocation.id, allocation);
  }

  getAllocationCarryovers(fund_id: number) {
    return this.get(`/allocations/carryovers?fund_id=${fund_id}`);
  }

  applyAllocationCarryovers(fund_id: number, ids: Array<number>) {
    return this.post(`/allocations/carryovers?fund_id=${fund_id}`, ids);
  }

  sendAllocationsToPlans(grantId: number, durationId: number) {
    return this.post(`/allocations/plan_reallocation?fund_id=${grantId}&duration_id=${durationId}`);
  }

  getUnsentAllocations(grantId: number) {
    return this.get(`/allocations/plan_reallocation?fund_id=${grantId}`);
  }

  createRCContributor(parentKey: string, contributor: any) {
    return this.post(`/rc_management/contributors/${parentKey}`, contributor);
  }


  /** Entities **************************************************/

  updateCfad(cfad: any) {
    return this.put('/cfads/' + cfad.id, cfad);
  }

  getCfad(cfad_id: number) {
    return this.get('/cfads/' + cfad_id);
  }

  getMember(member) {
    return this.get(`/consortium/${member.proposal_id}/member/${member.institution_id}/${member.duration_id}`);
  }

  addMember(member, voting_only: boolean) {
    return this.post(`/consortium/${member.proposal_id}/member/${member.institution_id}/${member.duration_id}?voting_only=${voting_only || false}`);
  }

  updateMemberVotingStatus(member, voting_only?) {
    return this.post(`/consortium/${member.proposal_id}/member/${member.institution_id}/${member.duration_id}/voting_status?voting_only=${voting_only || false}`);
  }

  removeMember(member) {
    return this.delete(`/consortium/${member.proposal_id}/member/${member.institution_id}/${member.duration_id}`);
  }

  getAnnualPlan(annualPlanId: number) {
    return this.get('/annualplans/' + annualPlanId);
  }

  updateAnnualPlan(annualPlan: any) {
    return this.put('/annualplans/' + annualPlan.id, annualPlan);
  }

  addAnnualPlanStrategy(strategy: any) {
    return this.post(`/annualplans/${strategy.annual_plan_id}/strategies`, strategy);
  }

  updateAnnualPlanStrategy(strategy: any) {
    return this.put(`/annualplans/strategies/${strategy.id}`, strategy);
  }

  deleteAnnualPlanStrategy(strategy: any) {
    return this.delete(`/annualplans/strategies/${strategy.id}`);
  }

  /** Fiscal Reports **************************************************/

  /* v2 */
  getFiscalReportStatus(options: { programKeys: Array<string>, parentKey?: string, fullProposal?: boolean, includeApprovalTasks?: boolean }) {
    const params = Object.entries(options)
      .filter(([_, value]) => typeof value !== 'undefined' && value !== null)
      .reduce((paramMap, [key, value]) => ({ ...paramMap, [key]: value }), {});
    return this.get('/fiscal_reports/status', { params }, 'v2');
  }

  getFiscalReportsForProposal(proposalId: number) {
    return this.get('/fiscal_reports/proposals/' + proposalId, undefined, 'v2');
  }

  getFiscalReportsForInstitution(institutionId: number, fundId: number) {
    return this.get(`/institutions/${institutionId}/fiscal_reports`, { params: { fund_id: fundId } }, 'v2');
  }

  /* v1 */
  getExpenditures(filter: {
    institution_ids?: number[],
    fund_ids?: number[],
    proposal_ids?: number[],
    duration_ids?: number[],
    year_duration_ids?: number[],
    allocation_duration_ids?: number[],
    unearmarked_only?: boolean
  }) {
    const params = this.buildFilterParams(filter, false);
    return this.get('/expenditures', { params });
  }

  getFiscalReports(filter: Object = {}) {
    const params = this.buildFilterParams(filter, false);
    return this.get('/fiscal_reports', { params });
  }

  getFundFiscalReports() {
    return this.get(`/fiscal_reports/iplan`);
  }

  getProposalFiscalReports(proposal_id: number) {
    return this.get(`/fiscal_reports_proposal/${proposal_id}`);
  }

  saveFiscalReport(proposal_id: number, fiscal_report: any, upsert_lines: boolean = true) {
    return this.post(`/fiscal_reports/${proposal_id}?upsert_lines=${upsert_lines}`, fiscal_report);
  }

  upsertFiscalReportLines(report_id: number, lines: Array<any>) {
    return this.put(`/fiscal_reports/${report_id}/lines`, lines);
  }

  getExpenditureToDate(proposal_id: number, institution_id: number, duration_id?: number) {
    const queryString = duration_id ? `?duration_id=${duration_id}` : '';
    return this.get(`/fiscal_reports/${proposal_id}/${institution_id}/amountToDate${queryString}`);
  }

  canSubmitReport(proposal_id: number, institution_id: number, duration_id: number) {
    return this.get(`/fiscal_reports/${proposal_id}/${institution_id}/canSubmit?duration_id=${duration_id}`);
  }

  canUnsubmitReport(proposal_id: number, institution_id: number, duration_id: number) {
    return this.get(`/fiscal_reports/${proposal_id}/${institution_id}/canUnsubmit?duration_id=${duration_id}`);
  }

  hasEncumberedFunds(fund_id: number, fundingSourceId?: number) {
    return this.get(`/fiscal_reports/has_encumbered_funds?fund_id=${fund_id}${ fundingSourceId ? '&funding_source_id=' + fundingSourceId : ''}`);
  }

  submit1491Extensions(proposalId: number, durationId: number, config: { dueDate?: string, added?: Array<number>, removed?: Array<number>, updated?: Array<number> }) {
    return this.post('/caep_reporting_extensions', { proposalId, durationId, ...config });
  }

  /** Program Areas ****************************************************/
  getProgramAreaReports(
    proposal_ids: number[],
    institution_ids: number[],
    duration_ids: number []
  ) {
    let params = new HttpParams();

    if (proposal_ids && proposal_ids.length) {
      params = params.append('proposal_ids', proposal_ids.join(','))
    }

    if (institution_ids && institution_ids.length) {
      params = params.append('institution_ids', institution_ids.join(','))
    }

    if (duration_ids && duration_ids.length) {
      params = params.append('duration_ids', duration_ids.join(','))
    }

    return this.get('/program_area_reports', { params });
  }

  createProgramAreaReport(proposal_id: number, institution_id: number, duration_id: number, lines: any[] = []) {
    const payload = { proposal_id, institution_id, duration_id, lines };
    return this.post('/program_area_reports', payload);
  }

  upsertProgramAreaReportLine(program_area_report_id: number, line: Model.ProgramAreaReportLine ) {
    return this.put(`/program_area_reports/${program_area_report_id}/lines`, line);
  }

  updateProgramAreaReportState(program_area_report_id: number, state_id: number) {
    return this.put(`/program_area_reports/${program_area_report_id}`, { state_id });
  }

  /** Proposal Notifications **************************************************/
  sendProposalReminder(reminder: Model.ProposalReminder) {
    return this.post(`/proposals/${reminder.proposal_id}/reminder`, reminder);
  }

  /** Files **************************************************/
  getFiles(filter: Object = {}, eager: boolean = true) {
    let params = this.buildFilterParams(filter);

    params = params.append('eager', eager.toString());

    return this.get('/files', { params });
  }

  saveFile(file: any) {
    return this.post('/files/', file);
  }

  saveUrl(urlOpts: any) {
    return this.post('/urls', urlOpts);
  }

  deleteFile(id: number): Observable<any> {
    return this.delete(`/files/${id}`);
  }

  /** Tasks **************************************************/
  listTasks(
    filter: {
      task_type?: string,
      user_id?: boolean,
      institution_id?: number,
      proposal_id?: number,
      cfad_id?: number,
      annual_plan_id?: number,
      duration_id?: number | string,
      report_id?: number,
      role_id?: number,
      fund_id?: number,
      completed?: boolean,
      deleted?: boolean,
      performed_by_id?: number
    } = {},
    current_only: boolean = false
  ) {
    let params = this.buildFilterParams(filter);
    params = params.append('current_only', current_only.toString());

    return this.get('/tasks', { params });
  }

  createTask(payload) {
    return this.post('/tasks', payload);
  }

  completeTask(payload) {
    return this.put('/tasks', payload);
  }

  undoTask(payload) {
    return this.put('/tasks/undo', payload);
  }

  updateTask(task_id: number, payload) {
    return this.put(`/tasks/${task_id}`, payload);
  }

  notifyTask(payload) {
    return this.post('/tasks/notify', payload);
  }

  /** Funds **************************************************/
  getFunds(
    filter: {
      key?: string,
      parent_key?: string,
      plan_length?: number
    } = {}
  ) {
    const params = this.buildFilterParams(filter);
    return this.get('/funds', { params });
  }

  getv2Funds(
    filter: {
      id?: number,
      key?: string,
      parent_key?: string,
      plan_length?: number,
      slim?: boolean
    } = {},
    include_deleted?: boolean,
    slim?: boolean
  ) {
    let params = this.buildFilterParams(filter);
    if (!include_deleted && !params.has('deleted')) {
      // add deleted as filtering criteria.
      params = params.append('filter[deleted]', 'false');
    }
    if (slim) {
      params = params.append('slim', true);
    }

    return this.get('/funds', { params }, 'v2');
  }

  getFundMasterCopies(parentKey: string) {
    return this.get(`/funds/${parentKey}/master_copies`);
  }

  updateFund(body: any) {
    return this.put('/funds', body);
  }

  createFund(body: any) {
    return this.post('/funds', body);
  }

  deleteFundById(id: number) {
    return this.delete('/funds/' + id);
  }

  duplicateFund(sourceFundId: any, body: any) {
    return this.post(`/funds/${sourceFundId}/duplicate`, body);
  }

  getFundsObjectCodes() {
    return this.get('/funds_object_codes');
  }

  getSmallPrograms() {
    return this.get('/small_programs');
  }

  getFundVersion(fundId: number) {
    return this.get(`/funds/${fundId}/version`);
  }

  /** Events **************************************************/
  getEvents(fundId: number, all?: boolean) {
    const queryParam = `?fund_id=${fundId}&eager=true${all ? '&all=true' : ''}`;
    return this.get('/events' + queryParam);
  }

  createEvent(event: any) {
    return this.post('/events', event);
  }

  updateEvent(event: any) {
    return this.put('/events', event);
  }

  deleteEvent(eventId: any) {
    return this.delete(`/events/${eventId}`);
  }

  getEventNames() {
    return this.get('/lookups/event_names');
  }

  getEventTypes() {
    return this.get('/lookups/event_types');
  }

  getEventExpired(fundId: number, eventTypes: string[]) {
    return this.get('/events/expired', {
      params: {
        fund_id: fundId,
        event_types: eventTypes.join(',')
      },
    });
  }

  /** Messages **************************************************/
  getMessageTemplates(
    filter: {
      fund_id?: number,
      state_id?: number,
      event_id?: number
    } = {}
  ) {
    return this.get('/message_templates', { params: filter });
  }

  getSentMessages(fundId: number) {
    return this.get('/message_templates/sent', { params: { fund_id: fundId }});
  }

  getMessageTemplateById(id: number) {
    return this.get(`/message_templates/${id}`);
  }

  createMessageTemplate(msg) {
    return this.post('/message_templates', msg);
  }

  updateMessageTemplate(msg) {
    return this.put(`/message_templates/${msg.id}`, msg);
  }

  previewMessage(msgId: number) {
    return this.getAsText(`/message_templates/${msgId}/preview`);
  }

  sendMessage(msgId: number) {
    return this.post(`/message_templates/${msgId}/deliver`);
  }

  sendTestMessage(msgId: number, recipients) {
    return this.post(`/message_templates/${msgId}/deliver`, recipients);
  }

  /** User Messages **************************************************/
  getUserMessages(userId: number, messageState: string, limit: number, programIds: Array<number> = []) {
    const params = this.buildFilterParams({ filter_type: messageState, limit, program_ids: programIds }, false);
    return this.get(`/users/${userId}/messages`, { params });
  }

  markUserMessages(userId: number, value: boolean, markType: string, ids: Array<number> = []) {
    const body = { mark: markType, value, user_message_ids: ids };
    return this.put(`/users/${userId}/messages`, body);
  }

  /** Proposal Strategy **************************************************/
  createProposalStrategy(strategy: Model.Strategy) {
    return this.post(`/proposals/${strategy.proposal_id}/strategies`, strategy);
  }

  updateProposalStrategy(strategy: Model.Strategy) {
    return this.put(`/proposals/${strategy.proposal_id}/strategies/${strategy.id}`, strategy);
  }

  deleteProposalStrategy(strategy: any) {
    return this.delete(`/proposals/${strategy.proposal_id}/strategies/${strategy.id}`);
  }

  /** Proposal Attribute **************************************************/
  upsertAttribute(attribute: Model.Attribute, fund_id?: number, institution_id?: number) {
    if (institution_id) {
      return this.put(`/institutions/${institution_id}/attribute`, attribute);
    } else if (fund_id) {
      return this.put(`/funds/${fund_id}/attribute`, attribute, 'v2')
    }
    return this.put(`/proposals/${attribute.proposal_id}/attribute`, attribute);
  }

  deleteAttribute(attribute: Model.Attribute, fund_id?: number, institution_id?: number) {
    if (institution_id) {
      return this.delete(`/institutions/${institution_id}/attribute`, attribute);
    } else if (fund_id) {
      return this.delete(`/funds/${fund_id}/attribute`, attribute, 'v2')
    }
    return this.delete(`/proposals/${attribute.proposal_id}/attribute`, attribute);
  }

  createEffortArea(ea: any) {
    return this.post(`/effort_area`, ea);
  }

  updateEffortArea(ea: any) {
    return this.put(`/effort_area/${ea.id}`, ea);
  }

  deleteEffortArea(ea: any) {
    return this.delete(`/effort_area/${ea.id}`);
  }

  cloneEffortArea(eaId: number, body: {
    replacers?: Array<{ key: string, value: any }>,
    excludeAttributes?: Array<string>,
    excludeEffortAreas?: Array<string>,
    targetModel: string }) {
    return this.post(`/effort_area/${eaId}/clone`, body);
  }

  perkinsCopyResponses(proposalId: number) {
    return this.post('/perkins/copy_responses/' + proposalId);
  }

  perkinsGetPriorPrograms(proposalId: number, institutionId: number) {
    return this.get(`/perkins/copy_sub_application/${proposalId}/${institutionId}`);
  }

  perkinsCopyPriorPrograms(proposalId: number, institutionId: number, coreIndicators: boolean, programIds: Array<number>) {
    return this.post(`/perkins/copy_sub_application/${proposalId}/${institutionId}`, { core_indicators: !!coreIndicators, program_ids: programIds });
  }

  /** Resource Notes **************************************************/
  upsertResourceNote(note: any) {
    return this.put(`/resource_notes`, note);
  }

  deleteResourceNote(id: number) {
    return this.delete(`/resource_notes/${id}`);
  }

  /** Reporting **************************************************/
  extendReportingPeriods(settings: any) {
    return this.put(`/proposals/${settings.proposal_id}/reporting`, settings);
  }

  /** One-Off API for GP */
  openNewGPYear(durationId: number) {
    return this.post(`/guidedPathways/openYear?duration_id=${durationId}`);
  }

  getMaintenanceStatus(): Observable<Model.Maintenance> {
    return this.get('/maintenance');
  }

  /** Document Types **************************************************/
  addCustomDocType(docType: Model.DocumentType) {
    return this.post('/document_types', docType);
  }

  editCustomDocType(docType: Model.DocumentType) {
    return this.put(`/document_types/${docType.id}`, docType);
  }

  /** Surveys **************************************************/
  canUnpublishSurvey(surveyTemplateId: number) {
    return this.get(`/surveys/${surveyTemplateId}/canUnpublish`);
  }

  /** Invoicing **************************************************/
  listInvoices(fundId: number) {
    return this.get(`/invoices?fund_id=${fundId}`);
  }

  getInvoiceById(invoiceId: number) {
    return this.get(`/invoices/${invoiceId}`);
  }

  createInvoice(invoice: Partial<Model.EAInvoice>) {
    return this.post('/invoices', invoice);
  }

  updateInvoice(invoice: Model.EAInvoice) {
    return this.put(`/invoices/${invoice.id}`, invoice);
  }

  deleteInvoice(invoiceId: number) {
    return this.delete(`/invoices/${invoiceId}`);
  }

  submitInvoice(invoiceId: number) {
    return this.post(`/invoices/${invoiceId}/submit`);
  }

  markInvoiceAsPaid(invoiceId: number) {
    return this.post(`/invoices/${invoiceId}/paid`);
  }

  /** Apprenticeship Programs  **************************************************/
  listApprenticeshipPrograms(filterOptions?: object): Observable<any> {
    let params = new HttpParams();
    if (filterOptions) {
      Object.entries(filterOptions).forEach(([key, value]) => {
        if (!(value instanceof Array)) {
          params = params.append(key, value);
        } else if (value.length) {
          params = params.append(key, value.join(','));
        }
      });
    }

    return this.get('/apprenticeship_programs', { params });
  }

  /** Calendars **************************************************/
  getActionItems(options: any) {
    const params = this.buildFilterParams(options, false);
    return this.get('/action_items', { params });
  }

  setActionItemVisibility(actionItemId: number, hide: boolean) {
    return this.post(`/action_items/${actionItemId}/${ hide ? 'hide' : 'unhide' }`);
  }

  syncCalendar(client: string) {
    return this.post(`/calendars/${client}`);
  }

  getCalendarEvents(options: any) {
    const params = this.buildFilterParams(options, false);
    return this.get('/calendar_events', { params });
  }

  getCalendarICS() {
    return this.getAsText('/calendars/ics');
  }

  /** Dashboards **************************************************/
  getDashboards() {
    return this.get('/dashboards');
  }

  createDashboard(dashboard) {
    return this.post('/dashboards', dashboard);
  }

  updateDashboard(dashboardId, updates) {
    return this.put(`/dashboards/${dashboardId}`, updates);
  }

  deleteDashboard(dashboardId: number) {
    return this.delete(`/dashboards/${dashboardId}`);
  }

  /** Categories **************************************************/
  createCategory(category) {
    return this.post('/categories', category);
  }

  updateCategory(categoryId, updates) {
    return this.put(`/categories/${categoryId}`, updates);
  }

  scheduleHeadcountCertification(duration_id: number, date_time: string) {
    return this.put('/scheduler/headcount_certification', { duration_id, date_time });
  }

  getHeadcountCertificationDelay(duration_id: number) {
    return this.get('/scheduler/headcount_certification?duration_id=' + duration_id);
  }

  cancelHeadcountCertificationDelay(duration_id: number) {
    return this.delete('/scheduler/headcount_certification?duration_id=' + duration_id);
  }
}
