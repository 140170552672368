/**
 * Barrel all of the services within this directory
 */

export * from './analytics.service';
export * from './api.service';
export * from './attributes.service';
export * from './lookup.service';
export * from './permissions.service';
export * from './program.service';
export * from './title.service';

/*
 * List available core services
 */
import { AnalyticsService } from './analytics.service';
import { ApiService } from './api.service';
import { AttributesService } from './attributes.service';
import { DashboardFilterService } from './dashboard-filter.service';
import { LookupService } from './lookup.service';
import { PermissionsService } from './permissions.service';
import { ProgramService } from './program.service';
import { TitleService } from './title.service';

export const CoreServices = [
  AnalyticsService,
  ApiService,
  AttributesService,
  DashboardFilterService,
  LookupService,
  PermissionsService,
  ProgramService,
  TitleService,
];
