import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AnalyticsService, ApiService, LookupService, ProgramService, EVENT_ACTION, EVENT_CATEGORY } from '@app-services';
import { FilterType, EnumErrorTypes, Resource, Proposal, IPlanProposal, Fund } from '@app-models';
import { Store } from '@ngrx/store';
import { Actions, State, Model } from '@app-ngrx-domains';
import { AREAS, PROGRAM_KEYS } from '@app/core/consts';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
})

export class BookmarksComponent implements OnInit {
  // view templates go here
  @ViewChild('titleTmpl', {static: true}) titleTmpl: TemplateRef<any>;
  @ViewChild('areaTmpl', {static: true}) areaTmpl: TemplateRef<any>;
  @ViewChild('instTmpl', {static: true}) instTmpl: TemplateRef<any>;
  @ViewChild('yearTmpl', {static: true}) yearTmpl: TemplateRef<any>;
  @ViewChild('fundTmpl', {static: true}) fundTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) statusTmpl: TemplateRef<any>;
  @ViewChild('actionTmpl', {static: true}) actionTmpl: TemplateRef<any>;
  // columns def
  columns: any[];
  filingDate: any;
  // display text
  projectLongName = 'Bookmarks';
  analyticsFundName = 'Bookmarks';
  filters = [
    { field: 'title', type: FilterType.Text, name: 'Title' },
    { field: 'fundName', type: FilterType.Single, name: 'Fund' }
  ];

  toDeleteBookmark: Model.ResourceNote;
  proposalDeleted = false;
  proposalList: any[] = [];
  filteredProposals: any[];
  sortState: {
    column: string,
    descending: number
  };

  ariaState: {
    title: string,
    fund: string
  };

  filter: FormGroup;
  filterState: { title: string, fund: number };
  possibleFunds: Model.SelectOption[];

  constructor(
    private apiService: ApiService,
    private store: Store<State>,
    private fb: FormBuilder,
    private analyticsService: AnalyticsService,
    private programService: ProgramService,
    private lookupService: LookupService,
  ) {
    this.filterState = {
      title: '',
      fund: null
    };

    this.sortState = {
      column: 'none',
      descending: -1
    };

    this.ariaState = {
      title: 'none',
      fund: 'none'
    };

    this.filter = this.fb.group(this.filterState);
    this.possibleFunds = [];
  }

  ngOnInit() {
    this.getBookmarks();
    this.columns = [
      {
        prop: 'title',
        name: 'Title',
        sortable: true,
        cellTemplate: this.titleTmpl,
        cellDataQa: 'bookmark_title'
      },
      {
        prop: 'institution',
        name: 'Institution',
        sortable: true,
        cellTemplate: this.instTmpl,
        cellDataQa: 'bookmark_institution',
        headerCssClass: 'col-12-rem'
      },
      {
        prop: 'area',
        name: 'Type',
        sortable: true,
        cellTemplate: this.areaTmpl,
        cellDataQa: 'bookmark_area',
        headerCssClass: 'col-8-rem'
      },
      {
        prop: 'fundName',
        name: 'Fund',
        sortable: true,
        cellTemplate: this.fundTmpl,
        cellDataQa: 'bookmark_fund',
        headerCssClass: 'col-10-rem'
      },
      {
        prop: 'period',
        name: 'Period',
        sortable: true,
        cellTemplate: this.yearTmpl,
        cellDataQa: 'bookmark_period',
        headerCssClass: 'col-8-rem'
      },
      // {
      //   prop: 'state', name: 'Status',
      //   sortable: false,
      //   cellTemplate: this.statusTmpl,
      //   cellDataQa: 'bookmark_status',
      //   headerCssClass: 'col-8-rem'
      // },
      {
        prop: 'action',
        name: 'Actions',
        sortable: false,
        cellTemplate: this.actionTmpl,
        cellDataQa: 'bookmark_action',
        headerCssClass: 'col-6-rem align-center',
        cellCssClass: 'align-center'
      }
    ];
  }

  getBookmarks() {
    this.store.dispatch(Actions.Layout.showBusySpinner(true));
    this.apiService.getBookmarks().subscribe((response: Resource[]) => {
      if (response.length) {

        this.proposalList = response.map(resource => {
          const p = resource.proposal;

          let fund_ids = [];
          let fundName: string;
          let program: Model.Fund;

          if (resource.fund_id) {
            fund_ids = [resource.fund_id];
          } else if (resource.proposal) {
            fund_ids = resource.proposal.fund_ids;
          }

          if (fund_ids.length) {
            program = this.programService.getProgramById(fund_ids[0]);
            fundName = Fund.getShortestName(program);

            // If the proposal belongs to a child program, map the fund_id to the parent program
            const parentProgram = this.programService.getParentProgramById(fund_ids[0]);
            if (!!parentProgram && (parentProgram.id !== fund_ids[0])) {
              fund_ids = [parentProgram.id];
            }
          }

          const { route, queryParams } = Resource.resourceLink(resource, program) || {};
          const { title, area } = Resource.resourceTitle(resource, this.programService) || {};

          return {
            fund_ids: fund_ids,
            fundName: fundName,
            project_state: this.rowState(p, resource, program),
            project_period: this.rowDuration(resource),
            // bookmark: ResourceNote.bookmarkPayload(resource), // todo: this function should be on resource
            bookmark: Resource.resourcePayload(resource),
            link: route,
            queryParams: queryParams,
            title: title,
            area: area,
            institution: resource.institution_name || 'N/A',
            deleted: p && p.deleted || false,
          }
        });
      } else {
        this.proposalList = [];
        this.filteredProposals = [];
      }
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
    }, (error) => {
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
      this.store.dispatch(Actions.App.setError({
        location: this.constructor.name,
        raw: error,
        show: true,
        type: EnumErrorTypes.api
      }));
    });
  }

  private rowState(proposal: Proposal, resource: Model.Resource, program: Model.Fund) {
    const state = resource.state;
    const area = resource.area_name;
    if (state && state.state_name) {
      return state.state_name;
    }

    if (proposal && proposal.id) {
      if (state) {
        return state.state_name;
      } else if (proposal.statuses) {
        if (area === AREAS.FISCAL_REPORT) {
          return IPlanProposal.getFiscalReportState(proposal);

        } else if (area) {
          return IPlanProposal.getCurrentState(proposal);
        }
      } else if (proposal.state_id) {
        return Proposal.stateName(proposal);
      } else if (Proposal.hasAreaState(proposal, area)) {
        return Proposal.getAreaState(proposal, area);
      }
    }

    return 'N/A';
  }

  private rowDuration(note) {
    if (note.duration_id) {
      return note.duration.name;
    } else if (note.proposal) {
      const programKey = note.proposal.funds[0].parent_key;
      if (note.area_name === AREAS.FISCAL_REPORT && programKey === PROGRAM_KEYS.GP) {
        return Proposal.fiscalReportCycle(note.proposal.duration_id, programKey);
      }
      return Proposal.programYearName(note.proposal);
    }
  }

  openDeleteBookmark(bookmark: Model.ResourceNote) {
    this.toDeleteBookmark = bookmark;
  }

  removeDeletedProposal(bookmark: Model.ResourceNote) {
    this.toDeleteBookmark = bookmark;
    this.proposalDeleted = true;
  }

  confirmDeleteBookmark() {
    this.removeBookmark(this.toDeleteBookmark);
    this.toDeleteBookmark = undefined;
    this.proposalDeleted = false;
  }

  dismissDeleteBookmarkAlert() {
    this.toDeleteBookmark = undefined;
    this.proposalDeleted = false;
  }

  updateProposalList(proposals) {
    this.filteredProposals = proposals;
  }

  removeBookmark(bookmark: Model.ResourceNote) {
    const payload = {
        ...bookmark,
        note: { deleted: true, type: 'bookmark' },
    };
    this.store.dispatch(Actions.Layout.showBusySpinner(true));
    this.apiService.upsertResourceNote(payload)
        .subscribe((response) => this.getBookmarks(),
      (error) => {
        this.store.dispatch(Actions.Layout.showBusySpinner(false));
        this.store.dispatch(Actions.App.setError({
          location: this.constructor.name,
          raw: error,
          show: true,
          type: EnumErrorTypes.api
        }));
      }
    );

    this.analyticsService.logEvent(EVENT_CATEGORY.button, `${EVENT_ACTION.delete}-bookmark`, JSON.stringify(bookmark));
  }

  proposalLink(proposal: any) {
    return Proposal.routerLink(proposal);
  }

  formatProgramYear(proposal) {
    Proposal.programYearName(proposal);
  }

  logFilter(label: string) {
    this.analyticsService.logEvent(EVENT_CATEGORY.table, `${EVENT_ACTION.filter}-bookmarks`, label);
  }

  private logSort(sortName: string) {
    this.analyticsService.logEvent(EVENT_CATEGORY.table, `${EVENT_ACTION.sort}-bookmarks`, sortName);
  }

}
