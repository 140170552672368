import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, State } from '@app-ngrx-domains';
import { AppUtils } from '@app/core/utilities';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})

export class DashboardComponent implements OnInit {

  hideEventFeatures: boolean = true;

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute
  ) {
    const showCalendar = AppUtils.showWIPFeature('calendar');
    if (showCalendar || this.route.snapshot.queryParams.showCalendar) {
      this.hideEventFeatures = false;
    }
  }

  ngOnInit() {
    this.store.dispatch(Actions.Layout.setHeader({
      ...State.Layout.header,
      title: 'Dashboard'
    }));
  }
}
