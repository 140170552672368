<div>
  <div class="card card--no-padding notifications-list v2-styles margin-right">
    <div class="notifications-list-header">
      <po-toggle-tabs
        groupName="messageStates"
        [tabs]="['Unread', 'Read', 'Archived']"
        (tabSelected)="selectTab($event)">
      </po-toggle-tabs>
      <div class="actions-row">
        <div class="flex v2-icon-button notification-actions">
          <radio-check *ngIf="selectMsgList?.length" labelText="Select All" [labelHidden]="true" class="margin-right">
            <input #selectAllCheckbox inputRef type="checkbox" class="checkbox" [formControl]="form.controls['select_all']" id="qa_notifications_all_checkbox"
              (change)="checkAll()"/>
          </radio-check>
          <ng-container *ngIf="messagesSelected">
            <button *ngIf="selectedTab === MESSAGE_STATES.UNREAD" (click)="markMessages(MESSAGE_STATES.READ, true)"
              class="action-button action-button--small">
              <icon iconId="mark-read"></icon>
              Mark as Read
            </button>
            <button *ngIf="selectedTab === MESSAGE_STATES.READ" (click)="markMessages(MESSAGE_STATES.READ, false)"
              class="action-button action-button--small action-button--tertiary icon--left">
              <icon iconId="mark-read"></icon>
              Mark as Unread
            </button>
            <button *ngIf="selectedTab !== MESSAGE_STATES.ARCHIVED" (click)="markMessages(MESSAGE_STATES.ARCHIVED, true)"
              class="action-button action-button--small action-button--tertiary icon--left">
              <icon iconId="archive"></icon>
              Archive
            </button>
            <button *ngIf="selectedTab === MESSAGE_STATES.ARCHIVED" (click)="markMessages(MESSAGE_STATES.ARCHIVED, false)"
              class="action-button action-button--small action-button--tertiary icon--left">
              <icon iconId="archive"></icon>
              Unarchive
            </button>
            <button (click)="openDeleteMessageAlert()"
              class="action-button action-button--small action-button--tertiary icon--left">
              <icon iconId="delete"></icon>
              Delete
            </button>
          </ng-container>
        </div>
        <!-- TODO: Figure out filter usage -->
        <!-- <div *ngIf="false">
          <button #filterToggle *ngIf="!messagesSelected" (click)="toggleFilterDropdown($event)"
            class="filter-toggle-button action-button v2-icon-button action-button--small margin-left-sm icon--left">
            <icon iconId="filter"></icon>
          </button>
          <div #notificationFilterPopup *ngIf="filterOpen" class="dashboard-filter__popup-container" (click)="$event.stopPropagation()">
            <div class="dashboard-filter__popup">
              <div class="flex flex--justify-content-between margin-bottom">
                <h4>Filter</h4>
                <div>
                  <button
                    id="qa_filter_apply_button"
                    type="submit"
                    class="action-button action-button--primary action-button--small"
                    (click)="setFilter()">
                    Apply
                    <icon *ngIf="buttonActionIconId && !buttonActionLoading" [iconId]="buttonActionIconId"></icon>
                    <div class="loader-spinner-inline"></div>
                  </button>
                  <button
                    id="qa_filter_clear_all_button"
                    type="button"
                    class="action-button action-button--tertiary action-button--small margin-left-sm"
                    (click)="resetFilterDropdown()"
                    [disabled]="filterOpen && !hasFilters">
                    Clear All
                  </button>
                </div>
              </div>

              <div class="dashboard-filter__popup-scroll" [formGroup]="filterForm">
                <po-select id="qa_dashboard_filter_program"
                  labelText="Program"
                  [options]="programOptions"
                  [selectMultiple]="true">
                  <select inputRef class="po-select__select" formControlName="program_ids"></select>
                </po-select>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <!-- <div class="filter-list">
      <button
        *ngFor="let filter of filterList"
        class="filter-item action-button po-select__selection-list-item"
        (click)="filterRemoved(filter)"
        type="button"
        aria-label="Remove Filter">
        {{ filter.label }}
        <icon iconId="close" class="icon-host" data-qa="remove-selected-item"></icon>
      </button>
    </div> -->

    <div class="action-items-container" *ngIf="form" [formGroup]="form">
      <div *ngIf="!selectMsgList?.length" class="action-items-empty">
        <div class="action-items-empty__header">No Messages</div>
      </div>
      <cdk-virtual-scroll-viewport class="action-items-list" [itemSize]="itemSize">
        <div *cdkVirtualFor="let msg of selectMsgList; let index = i;"
          (click)="selectMessage(msg)"
          class="action-item notification-item notification-item--clickable"
          [ngClass]="{ 'notification-item--selected': msg.id === displayedMessage?.id }">
          <div class="flex flex--align-items-center margin-right-xs">
            <div class="notification-status" [ngClass]="{ 'notification-status--viewed': msg.read || msg.archived }"></div>
            <radio-check [labelText]="msg.selectName" [labelHidden]="true" (click)="$event.stopPropagation()" class="margin-bottom-none">
              <input inputRef #checkbox (change)="checkMessage(checkbox.checked, msg)" type="checkbox" class="action-list-item__checkbox" [formControl]="form.controls[msg.selectName]" id="qa_notifications_checkbox{{msg.selectName}}"/>
            </radio-check>
          </div>

          <div class="action-list-item__content">
            <div class="action-list-item__title" id="qa_action_list_item_title">
              <span class="text-ellipsis text-ellipsis--clamp-2">{{ formatContentTitle(msg) }}</span>
            </div>
          </div>

          <div class="subtitle subtitle--small margin-right-sm">
            {{ msg.created_at | po_date }}
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
  <div [ngSwitch]="!!displayedMessage" class="card notification-content v2-styles no-margin" [ngClass]="{ 'notification-content--empty': !displayedMessage }">
    <ng-container *ngSwitchCase="true">
      <div class="v2-icon-button notification-actions">
        <button *ngIf="!displayedMessage?.read && !displayedMessage.archived" class="action-button action-button--small icon--left" (click)="markMessages(MESSAGE_STATES.READ, true, displayedMessage)">
          <icon iconId="mark-read"></icon>
          Mark as Read
        </button>
        <button *ngIf="displayedMessage?.read && !displayedMessage.archived" class="action-button action-button--small icon--left" (click)="markMessages(MESSAGE_STATES.READ, false, displayedMessage)">
          <icon iconId="mark-read"></icon>
          Mark as Unread
        </button>
        <button *ngIf="!displayedMessage?.archived" class="action-button action-button--small icon--left" (click)="markMessages(MESSAGE_STATES.ARCHIVED, true, displayedMessage)">
          <icon iconId="archive"></icon>
          Archive
        </button>
        <button *ngIf="displayedMessage?.archived" class="action-button action-button--small icon--left" (click)="markMessages(MESSAGE_STATES.ARCHIVED, false, displayedMessage)">
          <icon iconId="archive"></icon>
          Unarchive
        </button>
        <button class="action-button action-button--small icon--left" (click)="openDeleteMessageAlert(true)">
          <icon iconId="delete"></icon>
          Delete
        </button>
      </div>
      <hr>
      <div class="flex flex--justify-content-between margin-bottom-sm">
        <h4 class="text-ellipsis flex-grow-1">{{ formatContentTitle(displayedMessage) }}</h4>
        <div class="subtitle subtitle--small margin-left-sm">{{ displayedMessage.created_at | po_date }}</div>
      </div>
      <div class="notification-body" [innerHtml]="displayedMessage.message_text"></div>
    </ng-container>
    <div *ngSwitchDefault id="qa_no_notifications_text">No message selected.</div>
  </div>
</div>

<ng-template #noSelection>
  <div class="card">
    <div id="qa_no_notifications_text" *ngIf="selectMsgList?.length === 0" class="empty-state">No message selected.</div>
  </div>
</ng-template>

<alert *ngIf="showDeleteMessageAlert"
  button-action="Delete"
  button-dismiss="Cancel"
  level="warning"
  (action)="closeDeleteMessageAlert(true)"
  (dismiss)="closeDeleteMessageAlert()">
    <p>
      Are you sure you want to delete {{ displayedMessageAlert ? 'this notification' : 'the selected notifications' }}?
    </p>
</alert>
