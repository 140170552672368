import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Model } from '@app-ngrx-domains';
import { FUND_SETTINGS, PROGRAM_KEYS, FUND_TYPES } from '@app-consts';
import { Utilities } from '../models/utilities';
import { ProgramSettings } from '../models/effort-areas/program-settings';
import { Fund } from '../models/fund';
import { cloneDeep, groupBy, flatten, sortBy, uniq, uniqBy } from 'lodash';
import { filter, map, take, switchMap, mergeMap, skipWhile } from 'rxjs/operators';
import { EnumErrorTypes } from '../models';

@Injectable({ providedIn: 'root' })
export class ProgramService {

  private _programSettings = {};
  private _programs: Array<Model.Fund>;
  private smallPrograms$ = new BehaviorSubject<Array<Model.Fund & { isActive?: boolean, isCompetitive?: boolean, isReporting?: boolean, settings_access?: boolean, review_access?: boolean, offer_access?: boolean }>>(undefined);
  private programsByKey: { [programKey: string]: Array<Model.Fund> };
  private visiblePrograms: Array<number> = [];

  private initialLoaded$ = new BehaviorSubject<boolean>(false);
  private programsLoaded$ = new BehaviorSubject<Array<string>>([]);

  private refreshFunds$ = new Subject();
  private refreshSmallPrograms$ = new Subject();
  private lookupProgram$ = new Subject<string>();

  constructor(
    private apiService: ApiService,
    private store: Store<State>,
  ) {
    // The switchMap will cancel any ongoing API requests so that it emits only once if multiple calls are made at the same time
    this.refreshFunds$.asObservable().pipe(
      switchMap(() => {
        this.initialLoaded$.next(false);
        return this.apiService.getv2Funds({}, false, true).pipe(take(1));
      })
    ).subscribe(async programs => {
      await this.refreshPrograms(programs);
      this.initialLoaded$.next(true);

      // If any programs have been fully-loaded, refetch them so we don't overwrite those entries
      const loadedPrograms = this.programsLoaded$.value;
      loadedPrograms.forEach(programKey => {
        this.lookupProgram$.next(programKey);
      });
    });

    this.refreshSmallPrograms$.asObservable().pipe(
      switchMap(() => {
        // Reset small programs and get fresh data
        this.smallPrograms$.next(undefined);
        return this.apiService.getSmallPrograms()
        .pipe(
          take(1),
          map((res) => res.map(program => {
            program.isActive = Fund.programIsActive(program);
            program.isCompetitive = this.getChildProgramsByParentKey(program.key).some(grant => grant.program_settings.is_rfa);
            program.isReporting = this.getChildProgramsByParentKey(program.key).some(grant => grant.program_settings.reporting_periods.length);
            return program;
          }))
        );
      })
    ).subscribe(smallPrograms => this.smallPrograms$.next(smallPrograms));

    this.lookupProgram$.asObservable().pipe(
      mergeMap((programKey: string) => {
        const lookups = [
          this.apiService.getv2Funds({ key: programKey }),
          this.apiService.getv2Funds({ parent_key: programKey })
        ];

        if (programKey === PROGRAM_KEYS.RCM) {
          // Lookup all RCM contributors as well
          lookups.push(this.apiService.getv2Funds({ key: PROGRAM_KEYS.RCM_C }));
        }

        return forkJoin([
          of(programKey),
          forkJoin(lookups)
        ])
      })
    ).subscribe(async ([programKey, results]) => {
      const funds = uniqBy(flatten(results), 'id');
      const fundIds = funds.map(fund => fund.id);

      const filteredPrograms = this.programs.filter(p => !fundIds.includes(p.id));
      filteredPrograms.push(...funds);
      await this.refreshPrograms(filteredPrograms);
      this.programsLoaded$.next(uniq([...this.programsLoaded$.value, programKey]));
    });

    this.store.select(Queries.Auth.getVisibleFunds).subscribe(fundIds => {
      this.visiblePrograms = fundIds;
      this.refreshFunds();
    });

    this.store.select(Queries.Fund.get).pipe(
      filter(f => !!(f && f.id))
    ).subscribe(fund => {
      const clonedFund = cloneDeep(fund);
      if (this._programs.find(p => p.id === clonedFund.id)) {
        this._programs = this._programs.map(program => (program.id === clonedFund.id) ? clonedFund : program);
      } else {
        this._programs.push(clonedFund);
      }
    });
  }

  public refreshFunds() {
    // Trigger the subject to make the API call
    this.refreshFunds$.next(true);
  }

  public refreshSmallPrograms() {
    // Trigger the subject to make the API call
    this.refreshSmallPrograms$.next(true);
  }

  private async refreshPrograms(programs) {
    programs = programs.filter(p => p.key); // Filter out programs that are not part of a workflow
    this._programs = programs;
    await this.refreshSmallPrograms();
    this.programsByKey = groupBy(programs, (value) => value.parent_key === PROGRAM_KEYS.SMALL_PROGRAMS ? value.key : value.parent_key || value.key);
    Object.keys(this.programsByKey).forEach(programKey => {
      const program = this.getParentProgramByKey(programKey);
      if (program) {
        // If the program doesn't have settings coming from the service, use the old settings defined in core/consts
        let settings = program.program_settings && program.program_settings.id ? program.program_settings : FUND_SETTINGS[program.id];
        if (program.key === PROGRAM_KEYS.IPLAN) {
          settings = FUND_SETTINGS[FUND_TYPES.IPLAN]; // There isn't an 'IPlan' fund, only children so we have to do this.
        }
        this._programSettings[programKey] = { name: program.name, ...settings };
      }
    });
  }

  // For checking if the initial "slim" lookup for all funds is complete
  public initialLoaded(): Observable<boolean> {
    return this.initialLoaded$.asObservable();
  }

  // For checking if the full data of a parent program has been loaded
  public programLoaded(key: string): Observable<boolean> {
    if (!this.programsLoaded$.value.includes(key)) {
      this.loadProgram(key);
    }

    return this.programsLoaded$.asObservable().pipe(
      map(programs => programs.includes(key))
    );
  }

  public async loadProgram(key: string) {
    this.programsLoaded$.next(this.programsLoaded$.value.filter(loaded => loaded != key));

    await this.initialLoaded().pipe(
      skipWhile(loaded => !loaded),
      take(1)
    ).toPromise();

    const parentProgram = this.getParentProgramByKey(key);
    this.lookupProgram$.next(parentProgram ? parentProgram.key : key);
  }

  public removePrograms(ids: Array<number>) {
    const filteredPrograms = this.programs.filter(p => !ids.includes(p.id));
    return this.refreshPrograms(filteredPrograms);
  }

  get smallPrograms(): Observable<Array<Model.Fund>> {
    return this.smallPrograms$.asObservable().pipe(
      filter(programs => !!programs),
      map(programs => (programs?.filter(p => this.visiblePrograms.includes(p.id))))
    );
  }

  get programs(): Array<Model.Fund> {
    return this._programs.filter(program => this.visiblePrograms.includes(program.id));
  }

  get parentPrograms(): Array<Model.Fund> {
    return this._programs.filter(p => !p.parent_key && this.visiblePrograms.includes(p.id));
  }

  get programSettings(): { [programKey: string]: Model.EAProgramSettings } {
    return this._programSettings;
  }

  // Takes a known programKey, assumes a parent program
  getParentProgramByKey(programKey: string): Model.Fund {
    if (this.programsByKey && !!this.programsByKey[programKey]) {
      const programs = this.programsByKey[programKey];
      return programs ? programs.find(p => p.parent_key === null || p.parent_key === PROGRAM_KEYS.SMALL_PROGRAMS) : undefined;
    }
  }

  // Used to lookup program information for a given key
  getProgramByKey(programKey: string): Model.Fund {
    return this.programs.find(p => p.key === programKey);
  }

  // Used to lookup program information for a given id
  getProgramById(programId: number) {
    return this.programs.find(p => p.id === programId);
  }

  getParentProgramById(programId: number) {
    let program = this.programs.find(p => p.id === programId);
    if (program?.parent_key && program.parent_key !== PROGRAM_KEYS.SMALL_PROGRAMS) {
      program = this.getParentProgramByKey(program.parent_key);
    }
    return program;
  }

  getProgramParentKeyById(programId: number) {
    const p = this.getProgramById(programId);
    return p ? p.parent_key || p.key : undefined;
  }

  getChildProgramsByParentKey(programKey: string, childKey?: string): Array<Model.Fund> {
    return this.programs.filter(p => p.parent_key === programKey && (childKey ? p.key === childKey : true));
  }

  getRCContributorByParentKey(parentKey: string): Model.Fund {
    return this.programs.find(p => p.parent_key === parentKey && p.key === PROGRAM_KEYS.RCM_C);
  }

  getProgramDurationId(program: Model.Fund): number {
    if (program && program.program_settings.base_duration_id) {
      return program.program_settings.base_duration_id;
    }
  }

  replaceProgram(program: Model.Fund) {
    this._programs = this._programs.map(p => p.id === program.id ? program : p);
  }

  deleteProgramById(programId: number) {
    this._programs = this._programs.filter(p => p.id !== programId);
  }

  getFiscalReportSettings(programKey: string): Array<Model.EAReportingPeriod> {
    const programSettings = this._programSettings[programKey];
    return programSettings ? programSettings.reporting_periods : [];
  }

  getProgramSurveys(programKey: string) {
    const program = this.getParentProgramByKey(programKey);
    return program ? program.survey_templates : [];
  }

  async verifyFundVersion(fundId: number): Promise<EnumErrorTypes> {
    try {
      const response = await this.apiService.getFundVersion(fundId).toPromise();
      const currentCopy = this.getProgramById(fundId);

      if (response && currentCopy && (currentCopy.program_settings?.id === response.version)) {
        return EnumErrorTypes.none;
      } else {
        const results = await this.apiService.getv2Funds({ id: fundId }).toPromise();
        if (results?.length) {
          const fund = results[0];
          this.replaceProgram(fund);
        }
        return EnumErrorTypes.user;
      }
    } catch (err) {
      return EnumErrorTypes.core;
    }
  }

  /** Utility Methods **/

  /**
   * Returns an array of multi-select options for parent programs
   * @param collection - return 'values' as arrays of all parent programIds for each key (Useful for IPlan)
   *  When collection is true: [ { value: [4, 5 ,6], label: 'IPlan' }]
   *  When collection is false: [ { value: 4, label: 'IPlan' }]
   */
  getProgramOptions(collection?: boolean, short_name?: boolean) {
    const options = [];
    Object.entries(this.programsByKey).forEach(([key, programs]) => {
      const program = this.getParentProgramByKey(key);
      if (!program) {
        return;
      }
      const name = short_name ? Fund.getShortestName(program) : program.name;
      if (collection) {
        const programIds = programs.filter(p => !p.parent_key || p.parent_key === PROGRAM_KEYS.SMALL_PROGRAMS).map(p => p.id);
        options.push({ value: programIds, label: name });
      } else {
        options.push({ value: program.id, label: name });
      }
    });

    return sortBy(options, 'label');
  }

  /**
   * Returns parent program from the route.
   * @param route
   */
  getParentProgramFromRoute(route: string): Model.Fund {
    // get the module name from the route as parent program key.
    const programKey = Utilities.programKeyFromRoute(route);
    return this.getParentProgramByKey(programKey);
  }

  /**
   * Returns the parent program id, or program ids of children from the route.
   * @param route
   */
  getProgramIdsFromRoute(route: string): Array<number> {
    const program = this.getParentProgramFromRoute(route);
    if (!!program) {
      switch (program.key) {
        case PROGRAM_KEYS.CAI: {
          const childrenV1 = this.getChildProgramsByParentKey(program.key, PROGRAM_KEYS.RFA);
          const childrenV2 = this.getChildProgramsByParentKey(program.key, PROGRAM_KEYS.RFA_v2);
          return [...childrenV1, ...childrenV2].map(child => child.id);
        }
        default: {
          if (program.is_small_program) {
            return this.getChildProgramsByParentKey(program.key, PROGRAM_KEYS.RFA).map(child => child.id);
          }
          return [program.id];
        }
      }
    } else {
      return [];
    }
  }

  /**
   * Returns the guidance for a workflow step if it exists.
   * @param programId
   * @param proposal_type
   * @param workflow_step
   */
  getWorkflowStepGuidance(programId: number, proposal_type: string, workflow_step: string): string {
    let guidance: string;
    const program = this.getProgramById(programId);
    const guidanceSettings = program.program_settings.guidances.find(g => g.proposal_type === proposal_type && g.workflow_step === workflow_step);
    if (guidanceSettings) {
      guidance = guidanceSettings.description;
    }

    return guidance;
  }

  /**
   * Returns guidance text.
   * @param workflowFilter
   * @param fieldName
   */
  getWorkflowStepFieldGuidanceText(workflowFilter: Model.GuidanceWorkflowFilter, fieldName: string): string {
    // get program
    const program = this.getProgramById(workflowFilter.programId);

    // return guidance text
    return ProgramSettings.getGuidanceText(program.program_settings.guidances, workflowFilter, fieldName);
  }

  /**
   * Returns participating institution types that are set up for given program.
   * If it's a child program (like RFA), then it gets its list from the budget
   * match requirements, if one's been setup.
   * @param programId
   */
  getParticipatingInstitutionTypes(programId: number, checkMatchRequirements = true, usePendingSettings = false): Array<string> {
    const institutionTypes = [];

    const program = this.getProgramById(programId);
    const isEditing = usePendingSettings && Fund.programSettingsIsEditing(program);
    const programSettings = isEditing ? program.program_settings_pending : program.program_settings;
    if (program.parent_key) {
      // see if there are budget match requirements.
      if (checkMatchRequirements && programSettings.budget_match_requirements.length) {
        programSettings.budget_match_requirements.forEach((b: Model.EABudgetMatchRequirement) => {
          if (!Utilities.isNil(b.match_percent) && b.match_percent >= 0) {
            institutionTypes.push(b.institution_type);
          }
        });
      } else {
        if (programSettings.participating_institution_types?.length) {
          programSettings.participating_institution_types.forEach((t: Model.AttributeValue) => institutionTypes.push(t.value));
        } else {
          // return list set up for parent.
          const parentProgram = this.getParentProgramByKey(program.parent_key);
          if (parentProgram?.program_settings?.participating_institution_types) {
            parentProgram.program_settings.participating_institution_types.forEach((t: Model.AttributeValue) => institutionTypes.push(t.value));
          }
        }
      }
    } else {
      // return the list set up for program.
      programSettings.participating_institution_types?.forEach((t: Model.AttributeValue) => institutionTypes.push(t.value));
    }

    return institutionTypes;
  }

  /**
   * Returns partner institution types from the parent program.
   * @param programId
   */
  getPartnerInstitutionTypes(programId: number): Array<string> {
    const institutionTypes = [];

    const program = this.getProgramById(programId);
    if (program.program_settings.partner_institution_types.length) {
      program.program_settings.partner_institution_types.forEach((t: Model.AttributeValue) => institutionTypes.push(t.value));
    } else {
      const parentKey = this.getProgramParentKeyById(programId);
      const parentProgram = this.getParentProgramByKey(parentKey);
      parentProgram.program_settings.partner_institution_types.forEach((t: Model.AttributeValue) => institutionTypes.push(t.value));
    }

    return institutionTypes;
  }

  /**
   * Recurses & finds the root fund.
   * @param program
   */
  getRootFund(program: Model.Fund): Model.Fund {
    if (program.parent_key && program.parent_key !== PROGRAM_KEYS.SMALL_PROGRAMS) {
      const parentProgram = this.programs.find(p => program.parent_key === p.key);

      return this.getRootFund(parentProgram);
    } else {
      return program;
    }
  }

  getBaseLink(programKey: string, suffix: string = ''): string {
    return `${this.isSmallProgram(programKey) ? `/${PROGRAM_KEYS.SMALL_PROGRAMS}` : ''}/${programKey}${suffix}`;
  }

  isSmallProgram(programKey) {
    const program = this.getProgramByKey(programKey);
    return program && program.is_small_program;
  }

  getFundingSources(grantId: number, usePending?: boolean): Array<Model.FundingSourceSettings> {
    const grant = this.getProgramById(grantId);
    const grantSettings = (usePending && grant.program_settings_pending) ? grant.program_settings_pending : grant.program_settings;
    const program = this.getParentProgramById(grantId);

    const fundedSources: Array<Model.FundingSourceSettings> = [...sortBy(grantSettings.rc_contributor_settings, 'fund_name')];

    // Always put parent program first
    fundedSources.unshift({
      ...grantSettings,
      fund_id: program.id,
      fund_name: program.name,
      start_year: grantSettings.base_duration_id
    });

    return fundedSources;
  }
}
