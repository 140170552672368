import { ModelBase } from './model.base';
import { Model } from '@app-ngrx-domains';

export interface IEmailResponse {
  from?: string,
  to?: string,
  cc?: string,
  bcc?: string,
  subject?: string,
  text?: string,
  html?: string,
  attachments?: Array<{filename: string, contentType: string, path: string}>
};

export interface IEmailResolution {
  accept: any,
  bounce: any,
  complaint: any,
}

export interface IUserMessage {
  id: number;
  created_at: string;
  updated_at: string;
  user_id: number;
  message_template_id: number;
  message?: Model.MessageTemplate;
  read: boolean;
  archived: boolean;
  deleted: boolean;
  email_response?: IEmailResponse;
  email_resolution?: IEmailResolution;
  message_title: string;
  message_text: string;
}

export class UserMessage extends ModelBase implements IUserMessage {
  public id: number;
  public created_at: string;
  public updated_at: string;
  public user_id: number;
  public message_template_id: number;
  public message: Model.MessageTemplate;
  public read: boolean;
  public archived: boolean;
  public deleted: boolean;
  public email_response: IEmailResponse;
  public email_resolution: IEmailResolution;
  public message_title: string;
  public message_text: string;

  constructor(raw?: any) {
    super();

    if (raw) {
      this.id = raw.id;
      this.created_at = raw.created_at;
      this.updated_at = raw.updated_at;
      this.user_id = raw.user_id;
      this.message_template_id = raw.message_template_id;
      this.message = raw.message;
      this.read = raw.read;
      this.archived = raw.archived;
      this.deleted = raw.deleted;
      this.email_response = raw.email_response;
      this.email_resolution = raw.email_resolution;
      this.message_title = raw.message_title;
      this.message_text = raw.message_text;
    }
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type UserMessage = IUserMessage;
    export type EmailResponse = IEmailResponse;
    export type EmailResolution = IEmailResolution;
  }
}
