import {Component, Input, Output, forwardRef, AfterContentInit, OnDestroy, EventEmitter, ElementRef, Injector, HostListener} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {CHAR_LIMITS} from '@app-consts';
import {environment} from '../../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import FroalaEditor from 'froala-editor';

@Component({
  selector: 'po-html-textarea',
  templateUrl: './html-text.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtmlTextComponent),
      multi: true
    }
  ]
})
export class HtmlTextComponent implements ControlValueAccessor, AfterContentInit, OnDestroy {
  @Input() enableParagraphFormat = false;
  @Input() enableCodeView = false;
  @Input() htmlVariables = false;
  @Input() htmlInputHeight: number;
  @Input() htmlInputMaxHeight: number;
  @Input() htmlCharLimit = CHAR_LIMITS.EXTRA_LONG;
  @Input() toggleToolbar = true;
  @Output() change = new EventEmitter();
  @Output() blur = new EventEmitter();

  // Enables editor focus when when clicking on the field's label
  @HostListener('body:click', ['$event.target.id'])
  onClick(labelId: string) {
    if (this.froalaElement && this.hostElement && labelId === this.hostElement.id + '_label') {
      this.froalaElement.focus();
    }
  }

  froalaOptions = {};
  froalaModel: any;

  private destroy$: Subject<boolean> = new Subject();
  private froalaEditor: any;
  private froalaElement: HTMLElement;
  private hostElement: HTMLInputElement;
  private hostControl: any;
  private isDisabled: boolean = false;

  constructor(
    private el: ElementRef,
    private injector: Injector
  ) {
    this.hostElement = this.el.nativeElement;
  }

  // *** Begin ControlValueAccessor methods.
  onChange = (_: any) => {};
  onTouched = () => {};
  registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

  /**
   * Update froala model with form content.
   * @param content
   */
  writeValue(content: any): void {
    this.froalaModel = content;
  }

  /**
   * Disables or enables control.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    if (this.froalaEditor) {
      if (isDisabled) {
        this.froalaEditor.edit.off();
      } else {
        this.froalaEditor.edit.on();
      }
    }
  }

  // *** End ControlValueAccessor methods.

  ngAfterContentInit() {
    // use injector, otherwise will get cyclic dependency error.
    this.hostControl = this.injector.get(NgControl);
    this.hostControl.statusChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.toggleFroalaValidation();
    });

    this.setFroalaOptions();

    if (this.htmlVariables) {
      this.createFroalaButtons();
    }
  }

  private setFroalaOptions() {
    const parent = this;

    const plugins = ['align', 'charCounter', 'link', 'lists'];
    if (this.enableParagraphFormat) {
      plugins.push('paragraphFormat');
    }
    if (this.enableCodeView) {
      plugins.push('codeView');
    }
    let placeholderText = '';
    if (this.isDisabled) {
      placeholderText = '--- editor disabled ---';
    }
    // rich buttons
    const richButtons = ['insertLink', 'insertHR'];
    if (this.htmlVariables) {
      richButtons.push('customInsert')
    }

    const options = {
      key: environment.froalaKey,
      attribution: false,  // turn off  "Powered By Froala message"
      pastePlain: true,
      heightMin: this.htmlInputHeight,
      heightMax: this.htmlInputMaxHeight,
      theme: 'po',
      placeholderText: placeholderText,
      charCounterMax: this.htmlCharLimit,
      charCounterCount: true, // show char counter
      paragraphFormatSelection: this.enableParagraphFormat ? true : false,
      pluginsEnabled: plugins,
      toolbarButtons: this.enableParagraphFormat
        ? [['bold', 'italic', 'underline'], ['paragraphFormat', 'outdent', 'indent', 'align', 'formatOL', 'formatUL'], richButtons, ['clearFormatting', 'html']]
        : [['bold', 'italic', 'underline'], ['align', 'formatOL', 'formatUL'], richButtons, ['clearFormatting', 'html']],
      linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
      events: {
        'initialized': function () {
          const editor = this;
          parent.froalaEditor = editor;
          parent.froalaElement = editor.el;
          editor.el.setAttribute('aria-labelledby', parent.hostElement.id + '_label'); // For screen-reader
          parent.showFroalaToolbar(false);
          if (parent.isDisabled) {
            editor.edit.off();
          } else {
            parent.toggleFroalaValidation();
          }
        },
        'contentChanged': function () {
          const value = this.html.get();
          parent.onChange(value);
          if (parent.change) {
            parent.change.emit(value);
          }
        },
        'focus': function () {
          parent.showFroalaToolbar(true);
        },
        'blur': function () {
          parent.showFroalaToolbar(false);
          if (parent.blur) {
            const value = this.html.get();
            parent.blur.emit(value);
          }
        },
      }
    };

    this.froalaOptions = options;
  }

  private createFroalaButtons() {
    FroalaEditor.DefineIcon('customInsertIcon', { NAME: 'plus'});

    FroalaEditor.RegisterCommand('customInsert', {
      title: 'Insert',
      type: 'dropdown',
      icon: 'customInsertIcon',
      options: {
        'fullName': 'Full Name',
        'firstName': 'First Name',
        'lastName': 'Last Name'
      },
      focus: false,
      refreshAfterCallback: true,
      callback: function (cmd, value, params) {
        let htmlValue = '';
        switch (value) {
          case 'fullName':
            htmlValue = 'user.full_name';
            break;
          case 'firstName':
            htmlValue = 'user.first_name';
            break;
          case 'lastName':
            htmlValue = 'user.last_name';
            break;
          default:
            break;
        }

        this.html.insert(`{{${htmlValue}}}`);
      },
    })
  }

  private toggleFroalaValidation() {
    if (!this.hostControl || !this.froalaElement) {
      return;
    }

    if (this.hostControl.control.valid) {
      this.froalaElement.classList.remove('ng-invalid');
    } else {
      this.froalaElement.classList.add('ng-invalid');
    }
  }

  private showFroalaToolbar(show: boolean) {
    if (this.froalaEditor && this.toggleToolbar) {
      if (show) {
        this.froalaEditor.toolbar.show();
      } else {
        this.froalaEditor.toolbar.hide();
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
