<ng-container [ngSwitch]="isPreview">
  <div *ngSwitchCase="true">
    <h4>{{institution?.name}}: Budget</h4>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>

  <div *ngSwitchDefault class="card">
    <div class="card-title">
      <h3>{{institution?.name}}: Budget</h3>
    </div>
    <ng-container *ngTemplateOutlet="content"></ng-container>

    <div *ngIf="canEdit" class="align-center">
      <div class="inline-button-group__container">
        <button *ngIf="allowCloning" class="action-button action-button--secondary action-button--small inline-button icon--left"
          (click)="showCloneAlert = true;">
          <icon iconId="duplicate"></icon>
          Copy From Previous Year
        </button>

        <button class="action-button action-button--secondary action-button--small inline-button icon--left"
          (click)="toggleShowForm()">
          <icon iconId="plus"></icon>
          New Budget Item
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #content>
  <table *ngIf="tableColumns?.length && budgetItems?.length; else empty" class="po-table">
    <thead>
      <tr>
        <th *ngFor="let column of tableColumns" scope="col" [class]="column.cssClass">{{column.name}}</th>
        <th *ngIf="canEdit" scope="col" class="col-5-rem align-center">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of budgetItems; trackBy: trackById">
        <td *ngFor="let column of tableColumns" [class]="column.cssClass">
          <ng-container *ngIf="column.template" [ngTemplateOutlet]="column.template" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          <ng-container *ngIf="column.value">{{ column.value(item) }}</ng-container>
        </td>

        <td *ngIf="canEdit" class="align-center">
          <ng-container [ngTemplateOutlet]="actionsTmpl" [ngTemplateOutletContext]="{ item: item }"></ng-container>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td class="align-left" [attr.colspan]="showRelatedActivities ? 4 : 3">Totals</td>
        <td class="align-right">{{ +getTotal('grant_funds_amount') | currency:'USD':'symbol':'1.0' }}</td>
        <td *ngIf="monetaryMatchPercent" class="align-right">{{ +getTotal('monetary_match_amount') | currency:'USD':'symbol':'1.0' }}</td>
        <td *ngIf="inkindMatchPercent" class="align-right">{{ +getTotal('inkind_match_amount') | currency:'USD':'symbol':'1.0' }}</td>
        <td *ngIf="canEdit"></td>
      </tr>
    </tfoot>
  </table>
</ng-template>

<full-page-modal *ngIf="showForm"
  heading="{{editingItem ? 'Edit' : 'Add'}} {{institution?.name}} Budget Item"
  [small]="true"
  [button-action]="editingItem ? 'Save Changes' : 'Add Budget Item'"
  [button-action-disabled]="form.invalid"
  button-cancel="Cancel"
  (action)="submitForm()"
  (cancel)="toggleShowForm()"
  (dismiss)="toggleShowForm()">
  <form [formGroup]="form" class="modal-body">
    <div class="columns columns--2 no-margin">
      <po-select id="object_code_select"
        labelText="Expenditure Type"
        [options]="objectCodeOptions"
        [selectOnce]="true">
        <select inputRef formControlName="object_code_id" class="po-select__select" aria-required="true" required></select>
      </po-select>
    </div>

    <input-field labelText="Brief description of expenditure">
      <po-html-textarea inputRef id="budget_description_input" formControlName="description"
        [htmlCharLimit]="descriptionLimit" aria-required="true" required>
      </po-html-textarea>
    </input-field>

    <po-select *ngIf="showRelatedActivities" id="related_activitiy_select"
      labelText="Related Activities"
      [options]="activityOptions"
      [selectMultiple]="true">
      <select inputRef formControlName="related_activities" class="po-select__select" [attr.aria-required]="requireRelatedActivities"></select>
    </po-select>

    <input-group headingText="Budget Item Amount" formGroupName="amounts" [control]="form.controls['amounts']" [required]="true">
      <div class="flex">
        <input-field labelText="Funds Amount" class="margin-right">
          <input inputRef id="budget_amount_input"
            formControlName="grant_funds_amount"
            integerCurrency placeholder="$"
            type="text">
        </input-field>

        <input-field *ngIf="monetaryMatchPercent"
          labelText="Monetary Match Amount" class="margin-right">
          <input inputRef id="monetary_amount_input"
            formControlName="monetary_match_amount"
            integerCurrency placeholder="$"
            type="text">
        </input-field>

        <input-field *ngIf="inkindMatchPercent"
          labelText="In-Kind Match Amount" class="margin-right">
          <input inputRef id="inkind_amount_input"
            formControlName="inkind_match_amount"
            integerCurrency placeholder="$"
            type="text">
        </input-field>
      </div>
    </input-group>

    <show-errors [control]="form" class="error-text"></show-errors>
  </form>
</full-page-modal>

<ng-template #empty>
  <p class="empty-state">No budget items have been added for {{institution?.name}}.</p>
</ng-template>

<ng-template #descriptionTmpl let-item="item">
  <div [innerHTML]="item.description"></div>
</ng-template>

<ng-template #activitiesTmpl let-item="item">
  <span *ngFor="let activity of item.related_activities" class="badge badge--flex badge--dark margin-right-xs">
    {{ activity.effort_area?.title || 'Unknown' }}
  </span>
</ng-template>

<ng-template #actionsTmpl let-item="item">
  <div class="inline-button-group__container">
    <button class="action-button action-button--tertiary action-button--small inline-button"
            title="Edit" (click)="editBudgetItem(item)">
      <icon iconId="edit"></icon>
    </button>

    <button class="action-button action-button--tertiary action-button--small inline-button"
            title="Delete" (click)="promptDeleteItem(item)">
      <icon iconId="delete"></icon>
    </button>
  </div>
</ng-template>

<alert *ngIf="!!showCloneAlert"
  level="info"
  button-action="Proceed"
  button-dismiss="Cancel"
  (dismiss)="showCloneAlert = false;"
  (action)="clonePrevious()">
  <p>Are you sure you want to copy all of {{institution?.name}}'s budget items from the previous year?</p>
</alert>

<alert *ngIf="!!deletingItem"
  level="warning"
  button-action="Delete"
  button-dismiss="Cancel"
  (dismiss)="deletingItem=undefined;"
  (action)="deleteBudgetItem()">
  <p>Are you sure you want to delete this budget item?</p>
</alert>
