import { IWorkflowState, Query, Queries, Root, Model, combineRootFactory } from '@app-ngrx-domains';
import { StateObservable } from '@ngrx/store';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IWorkflowState>('Workflow');

/**
 * Predefined queries to run against root states.
 */
export interface IWorkflowQueries {
  get: Query<{ [route: string]: Model.Workflow }>;
  getCurrent: Query<Model.Workflow>;
  getCurrentStep: Query<string>;
  getCurrentSteps: Query<Array<Model.WorkflowStep>>;
  getCurrentType: Query<string>;
  getByType: (key: string) => Query<Model.Workflow>;
  isVisible: Query<boolean>;
  isReview: Query<boolean>;
  showErrorStepper: Query<boolean>;
  getState: Query<IWorkflowState>;

  getModifiedFields: (step?: string) => Query<Model.ModifiedFields | Array<Model.ModifiedFields>>;
  isUnsubmitRequired: Query<boolean>;
}

Queries.Workflow = {
  get: fromRoot(state => state.workflows),
  getCurrent: fromRoot(state => state.workflows[state.current]),
  getCurrentStep: fromRoot(state => state.workflows[state.current] ? state.workflows[state.current].currentStep : undefined),
  getCurrentSteps: fromRoot(state => state.workflows[state.current] ? state.workflows[state.current].steps : undefined),
  getCurrentType: fromRoot(state => state.workflows[state.current] ? state.workflows[state.current].templateName : undefined),
  getByType: (type) => fromRoot(state => state.current === type ? state.workflows[type] : undefined),
  isVisible: fromRoot(state => state.workflows[state.current] ? state.workflows[state.current].show : false),
  isReview: fromRoot(state => state.workflows[state.current] ? state.workflows[state.current].isReview : false),
  showErrorStepper: fromRoot(state => state.showErrorStepper),
  getState: fromRoot(state => state),

  getModifiedFields: (step?: string) => fromRoot(state => {
    if (state.current && state.workflows[state.current]?.steps) {
      if (step) {
        const workflowStep = state.workflows[state.current].steps.find(s => s.route === step);
        return workflowStep ? workflowStep.modifiedFields : undefined;
      } else {
        return state.workflows[state.current].steps.reduce((summary, step) => {
          summary[step.title] = step.modifiedFields;
          return summary;
        }, {});
      }
    }
  }),
  isUnsubmitRequired: fromRoot(state => {
    if (state.current && state.workflows[state.current]?.steps) {
      return state.workflows[state.current].steps.some(step => {
        return Object.values(step.modifiedFields || {}).some(modified => modified.requireUnsubmit);
      });
    }
  })
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    Workflow: Query<IWorkflowState>;
  }

  interface Queries {
    Workflow: IWorkflowQueries;
  }
}
