import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'full-page-modal',
  templateUrl: './full-page-modal.component.html'
})
export class FullPageModalComponent {

  @Input('small') small = false;
  @Input('medium') medium = false;
  @Input('heading') heading: string;
  @Input('button-action') buttonAction: string;
  @Input('button-action-icon-id') buttonActionIconId: string;
  @Input('button-action-loading') buttonActionLoading = false;
  @Input('button-secondary-action') buttonSecondaryAction: string;
  @Input('button-cancel') buttonCancel: string;
  @Input('button-back') buttonBack: string;
  @Input('button-action-disabled') buttonActionDisabled = false;
  @Input('button-secondary-action-hide') buttonSecondaryActionHide = true;
  @Input('button-cancel-disabled') buttonCancelDisabled = false;
  @Input('button-back-disabled') buttonBackDisabled = false;
  @Input('return-value') returnValue: any;

  @Output() action: EventEmitter<string> = new EventEmitter();
  @Output() secondaryAction: EventEmitter<string> = new EventEmitter();
  @Output() dismiss: EventEmitter<string> = new EventEmitter();
  @Output() cancel: EventEmitter<string> = new EventEmitter();
  @Output() back: EventEmitter<string> = new EventEmitter();

  constructor() { }

  doAction(action: string) {
    this.action.emit(this.returnValue);
  }

  doSecondaryAction() {
    this.secondaryAction.emit(this.returnValue);
  }

  dismissModal(result: string) {
    this.dismiss.emit(this.returnValue);
  }

  cancelModal() {
    this.cancel.emit();
  }

  goBack() {
    this.back.emit();
  }

}
