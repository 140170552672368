<ng-container [ngSwitch]="isPreview">
  <!-- Preview -->
  <div *ngSwitchCase="true">
    <h4>{{cardTitle}}</h4>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>

  <!-- Workflow -->
  <div *ngSwitchDefault class="card">
    <div class="card-title">
      <h3>{{cardName}}: Budget</h3>
    </div>
    <ng-container *ngTemplateOutlet="content"></ng-container>

    <div *ngIf="allowAdd" class="align-center">

      <div class="inline-button-group__container">
        <button *ngIf="allowCloning" class="action-button action-button--secondary action-button--small inline-button icon--left"
          (click)="toggleCloneAlert()"
          [disabled]="disableCloning">
          <icon iconId="duplicate"></icon>
          Copy From Previous Year
        </button>

        <button class="action-button action-button--secondary action-button--small inline-button icon--left"
          (click)="toggleShowForm()">
          <icon iconId="plus"></icon>
          {{buttonText}}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #content>
  <table *ngIf="!!budgetItems?.length; else empty" class="po-table">
    <thead>
      <tr>
        <ng-container *ngFor="let column of columns">
          <ng-container *ngIf="column.previewOnly ? isPreview : true">
            <th scope="col" [class]="column.headerCssClass ? column.headerCssClass : ''">
              {{column.name}}
            </th>
          </ng-container>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of budgetItems; trackBy: trackById">
        <ng-container *ngFor="let column of columns">
          <ng-container *ngIf="column.previewOnly ? isPreview : true">
            <td [class]="getColumnClasses(item, column)">
              <ng-container [ngTemplateOutlet]="column.cellTemplate"
                            [ngTemplateOutletContext]="{ item: item }">
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th scope="row" [attr.colspan]="columnsTotalSpan">Total</th>
        <td class="align-right">{{+getBudgetTotal('direct_amount') | currency:'USD':'symbol':'1.0'}}</td>
        <td *ngIf="match" class="align-right">{{+getBudgetTotal('monetary_match_amount') | currency:'USD':'symbol':'1.0'}}</td>
        <td *ngIf="canEdit"></td>
      </tr>
    </tfoot>
  </table>
</ng-template>

<ng-template #objectCodeTmpl let-item="item">
  {{getObjectCode(item.object_code_id)}}
</ng-template>

<ng-template #yearTmpl let-item="item">
  {{getYear(item.duration_id)}}
</ng-template>

<ng-template #relatedActivityTmpl let-item="item">
  {{getActivity(item.related_activities)}}
</ng-template>

<ng-template #descriptionTmpl let-item="item">
  <div [innerHTML]="item.description"></div>
</ng-template>

<ng-template #ongoingOneTimeTmpl let-item="item">
  {{item.ongoing ? 'Ongoing' : 'One-time'}}
</ng-template>

<ng-template #programTmpl let-item="item">
  {{getProgramName(item.fund_id)}}
</ng-template>

<ng-template #amountTmpl let-item="item">
  <icon *ngIf="isRevised(item)" iconId="arrow"></icon>
  {{+getAmount(item) | currency:'USD':'symbol':'1.0'}}
</ng-template>

<ng-template #matchAmountTmpl let-item="item">
  {{+item.monetary_match_amount | currency:'USD':'symbol':'1.0'}}
</ng-template>

<ng-template #actionsTmpl let-item="item">
  <div *ngIf="canEditOrModify(item)" class="inline-button-group__container">
    <button class="action-button action-button--tertiary action-button--small inline-button"
            title="Edit" (click)="editBudgetItem(item)">
      <icon iconId="edit"></icon>
    </button>

    <button *ngIf="!isModifying" class="action-button action-button--tertiary action-button--small inline-button"
            title="Delete" (click)="promptDeleteItem(item)">
      <icon iconId="delete"></icon>
    </button>
  </div>
</ng-template>

<ng-template #empty>
  <p class="empty-state">No budget items have been added for {{cardName}}.</p>
</ng-template>

<full-page-modal *ngIf="showForm"
  heading="{{editingItem ? 'Edit' : 'Add'}} {{cardName}} Budget Item ({{getYear(yearId)}})"
  [small]="true"
  [button-action]="editingItem ? 'Save Changes' : 'Add Budget Item'"
  [button-action-disabled]="form.invalid"
  button-cancel="Cancel"
  (action)="submitForm()"
  (cancel)="toggleShowForm()"
  (dismiss)="toggleShowForm()">

  <form [formGroup]="form" class="modal-body" class="first-touch">
    <div class="columns columns--2 no-margin">
      <po-select id="object_code_select"
        labelText="Expenditure Type"
        [options]="isModifying ? modifiableObjectCodeOptions : objectCodeOptions"
        [selectOnce]="true">
        <select inputRef formControlName="object_code_id" class="po-select__select" aria-required="true" required></select>
      </po-select>
  
      <po-select id="activity_item_select"
        labelText="Activity that applies to this budget item"
        [options]="activityOptions"
        [selectOnce]="true">
        <select inputRef formControlName="related_activities" class="po-select__select" aria-required="true" required></select>
      </po-select>

      <ng-container [ngSwitch]="match">
        <ng-container *ngSwitchCase="true">
          <input-group headingText="Amounts" [legendHidden]="true" 
          [required]="true" [control]="form.get('amounts')">
            <div class="columns columns--2 no-margin">
              <ng-container [ngTemplateOutlet]="amountInput"></ng-container>
              <ng-container [ngTemplateOutlet]="matchInput"></ng-container>
            </div>
          </input-group>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <ng-container [ngTemplateOutlet]="amountInput"></ng-container>
        </ng-container>
      </ng-container>
  
      <input-group headingText="Is this expenditure one-time or ongoing?"
        [required]="true" [control]="form.get('ongoing')">
        <radio-check labelText="One-time">
          <input inputRef id="onetime_radio" type="radio" [value]="false" formControlName="ongoing">
        </radio-check>
        
        <radio-check labelText="Ongoing">
          <input inputRef id="ongoing_radio" type="radio" [value]="true" formControlName="ongoing">
        </radio-check>
      </input-group>
    </div>

    <input-field labelText="Brief description of expenditure">
      <po-html-textarea inputRef id="budget_description_input" formControlName="description"
        [htmlCharLimit]="descriptionLimit" aria-required="true" required>
      </po-html-textarea>
    </input-field>
  </form>
</full-page-modal>

<ng-template #amountInput>
  <ng-container [formGroup]="form">
    <ng-container formGroupName="amounts">
      <div><!-- div for preventing highlight from growing to fill vertical css grid space -->
        <div [class.padding-sm]="isRevised(editingItem)"
          [class.input--highlighted--neutral]="isRevisedHigherOrLower(editingItem, false)" 
          [class.input--highlighted--positive]="isRevisedHigherOrLower(editingItem, true)">
          <icon *ngIf="isRevised(editingItem)" iconId="arrow"></icon>
          <input-field labelText="Budget Amount" [class.mb-s]="match" [class.margin-bottom-xs]="isRevised(editingItem)">
            <input inputRef id="budget_amount_input"
              formControlName="direct_amount"
              integerCurrency placeholder="$"
              type="text" [attr.aria-required]="!match" [attr.required]="!match">
          </input-field>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #matchInput>
  <ng-container [formGroup]="form">
    <ng-container formGroupName="amounts">
      <input-field labelText="Match Amount" [class.mb-s]="match">
        <input inputRef id="match_amount_input"
          formControlName="monetary_match_amount"
          integerCurrency placeholder="$"
          type="text">
      </input-field>
    </ng-container>
  </ng-container>
</ng-template>

<alert *ngIf="!!deletingItem"
  level="warning"
  button-action="Delete"
  button-dismiss="Cancel"
  (dismiss)="deletingItem=undefined;"
  (action)="deleteBudgetItem()">
  <p>Are you sure you want to delete this budget item?</p>
</alert>

<alert *ngIf="!!showCloneAlert"
  level="info"
  button-action="Proceed"
  button-dismiss="Cancel"
  (dismiss)="toggleCloneAlert()"
  (action)="clonePrevious()">
  <p>Are you sure you want to copy all of {{cardName}}'s budget items from the previous year?</p>
</alert>
