<nav class="site-nav">
  <div class="site-nav__logo">
    <a class="site-nav__logo-link" title="Go to NOVA home" id="qa_nova_logo_link"
      [routerLink]="'/'">
      <img src="/assets/images/nova-logo-burst.svg" alt="NOVA: Plan. Invest. Track." class="site-nav__logo-link__image site-nav__logo-link__image--collapsed" aria-hidden="true" />
      <img src="/assets/images/{{logo.src}}" alt="{{logo.altText}}" class="site-nav__logo-link__image site-nav__logo-link__image--expanded" />
    </a>
  </div>

  <button type="button" title="{{ (expandNav ? 'Collapse' : 'Expand') + ' Menu' }}" [attr.aria-label]="(expandNav ? 'Collapse' : 'Expand') + ' Menu'" (click)="toggleExpand()" class="site-nav__expand icon-button icon-button--primary">
    <icon [iconId]="expandNav ? 'collapse' : 'expand'"></icon>
  </button>

  <ng-container *ngIf="(loggedin$|async) && !hideNav">
    <div class="site-nav__sudo-user" *ngIf="sudoLoggedIn$ | async">
      <span id="qa_left_nav_sudo_user_text" class="site-nav__sudo-user__text">
        Viewing as {{profileName$ | async}}
      </span>
      <button *ngIf="expandNav" class="text-link icon-link site-nav__sudo-user-logout" (click)="sudoLogout()">
        <icon iconId="swap"></icon>
        Switch to My Account
      </button>
    </div>

    <div id="qa_left_nav_container" class="site-nav__scroll-container">
      <div class="site-nav__scroll">

        <po-nav-tree>
          <po-nav-item poTreeNode itemText="Dashboard" itemUrl="/" [exactUrl]="true" id="dashboard" data-qa="dashboard" iconId="dashboard"></po-nav-item>

          <po-nav-group poTreeNode itemText="Programs" *ngIf="!selectedProgram" #treePrograms id="tree-programs" iconId="layers" class="site-nav__grow-section" [keepOpen]="true">
            <po-nav-item *ngFor="let program of programs" poTreeNode [itemText]="program.short_name" (buttonClick)="selectProgram(program)" [isButton]="true"></po-nav-item>
          </po-nav-group>

          <ng-container *ngIf="selectedProgram">
            <po-nav-item poTreeNode itemText="All Programs" (buttonClick)="selectProgram(null)" [isButton]="true" id="all-programs" data-qa="all-programs" iconId="disclosure-left" collapsedIconId="layers" class="site-nav__back-button"></po-nav-item>

            <div class="site-nav__grow-section">
              <ng-container *ngIf="selectedProgram.is_small_program">
                <po-nav-item poTreeNode [itemText]="selectedProgram.short_name" itemUrl="/sp/{{selectedProgram.key}}" [exactUrl]="true" id="tree-programs-{{selectedProgram.key}}" class="site-nav__section-heading"></po-nav-item>
                <ng-container *ngIf="selectedProgram.isActive">
                  <ng-container *ngIf="selectedProgram.isCompetitive">
                    <po-nav-item poTreeNode itemText="Applications" itemUrl="/sp/{{selectedProgram.key}}/applications" id="tree-programs-{{selectedProgram.key}}-applications" iconId="applications"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('review:view', selectedProgram.id)" poTreeNode itemText="Application Reviews" itemUrl="/sp/{{selectedProgram.key}}/reviews" id="tree-programs-{{selectedProgram.key}}-reviews" iconId="reviews"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('offer:edit', selectedProgram.id)" poTreeNode itemText="Application Funding" itemUrl="/sp/{{selectedProgram.key}}/funding" id="tree-programs-{{selectedProgram.key}}-funding" iconId="funding"></po-nav-item>
                  </ng-container>
                  <po-nav-item poTreeNode itemText="Plans" itemUrl="/sp/{{selectedProgram.key}}/plans" id="tree-programs-{{selectedProgram.key}}-plans" iconId="plans"></po-nav-item>
                  <po-nav-item *ngIf="selectedProgram.isReporting" poTreeNode itemText="Fiscal Reporting" itemUrl="/sp/{{selectedProgram.key}}/fiscal-reports" id="tree-programs-{{selectedProgram.key}}-reporting" iconId="fiscal-reporting"></po-nav-item>
                  <po-nav-item poTreeNode itemText="Analytics" itemUrl="/sp/{{selectedProgram.key}}/analytics" id="tree-programs-{{selectedProgram.key}}-analytics" iconId="line-chart"></po-nav-item>
                  </ng-container>

                <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/sp/{{selectedProgram.key}}/settings" id="tree-programs-{{selectedProgram.key}}-settings" iconId="gear">
                  <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/sp/{{selectedProgram.key}}/settings/details" id="tree-programs-{{selectedProgram.key}}-details"></po-nav-item>
                  <po-nav-item poTreeNode itemText="Funds" itemUrl="/sp/{{selectedProgram.key}}/settings/funds" id="tree-programs-{{selectedProgram.key}}-funds"></po-nav-item>
                  <po-nav-item poTreeNode itemText="Allocations" itemUrl="/sp/{{selectedProgram.key}}/settings/allocations" id="tree-programs-{{selectedProgram.key}}-allocations"></po-nav-item>
                  <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/sp/{{selectedProgram.key}}/settings/dates-messaging" id="tree-programs-{{selectedProgram.key}}-dates-messaging"></po-nav-item>
                  <po-nav-item poTreeNode itemText="Custom Surveys" itemUrl="/sp/{{selectedProgram.key}}/settings/surveys" id="tree-programs-{{selectedProgram.key}}-surveys"></po-nav-item>
                </po-nav-group>
              </ng-container>

              <ng-container *ngIf="!selectedProgram.is_small_program">
                <ng-container [ngSwitch]="selectedProgram.key">

                  <ng-container *ngSwitchCase="'cai'">
                    <po-nav-item poTreeNode itemText="Apprenticeship" itemUrl="/cai" [exactUrl]="true" id="tree-programs-cai" class="site-nav__section-heading"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Applications" itemUrl="/cai/applications" id="tree-programs-cai-applications" iconId="applications"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('review:view', selectedProgram.id)" poTreeNode itemText="Application Reviews" itemUrl="/cai/reviews" id="tree-programs-cai-reviews" iconId="reviews"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('offer:edit', selectedProgram.id)" poTreeNode itemText="Application Funding" itemUrl="/cai/funding" id="tree-programs-cai-funding" iconId="funding"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Plans" itemUrl="/cai/plans" id="tree-programs-cai-plans" iconId="plans"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Fiscal Reporting" itemUrl="/cai/fiscal-reports" id="tree-programs-cai-fiscal-reporting" iconId="fiscal-reporting"></po-nav-item>
                    <po-nav-item poTreeNode itemText="RSI Reporting" itemUrl="/cai/rsi" id="tree-programs-cai-rsi" iconId="clipboard-data"></po-nav-item>
                    <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/cai/settings" id="tree-programs-cai-settings" iconId="gear">
                      <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/cai/settings/details" id="tree-programs-cai-details"></po-nav-item>
                      <po-nav-item poTreeNode itemText="RSI" [itemUrl]="settingsRoutes['cai-rsi']" id="tree-programs-cai-rsi"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/cai/settings/dates-messaging" id="tree-programs-cai-dates-messaging"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Grants" itemUrl="/cai/settings/grants" id="tree-programs-cai-grants"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Allocations" itemUrl="/cai/settings/allocations" id="tree-programs-cai-allocations"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Custom Surveys" itemUrl="/cai/settings/surveys" id="tree-programs-cai-custom-surveys"></po-nav-item>
                    </po-nav-group>
                  </ng-container>

                  <ng-container *ngSwitchCase="'caep'">
                    <po-nav-item poTreeNode itemText="CAEP" itemUrl="/caep" [exactUrl]="true" id="tree-programs-aebg" class="site-nav__section-heading"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Consortia & Members" itemUrl="/caep/consortia" [alternateUrls]="['/caep/members']" id="tree-programs-caep-consortia" iconId="people"></po-nav-item>
                    <po-nav-group poTreeNode itemText="Reports" id="tree-programs-caep-analytics" iconId="line-chart">
                      <po-nav-item poTreeNode itemText="Monitor Reports" itemUrl="/caep/monitor-summary" id="tree-programs-caep-monitor"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Carryover Reports" itemUrl="/caep/carryover-reports" id="tree-programs-caep-carryover"></po-nav-item>
                    </po-nav-group>
                    <po-nav-item poTreeNode itemText="Fiscal Reporting" itemUrl="/caep/fiscal-reports" id="tree-programs-caep-fiscal-reporting" iconId="fiscal-reporting"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Program Area Reporting" itemUrl="/caep/program-area-reports" id="tree-programs-aebg-pa-reporting" iconId="clipboard-data"></po-nav-item>
                    <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/caep/settings" id="tree-programs-caep-settings" iconId="gear">
                      <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/caep/settings/details" id="tree-programs-caep-details"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Assurances" itemUrl="/caep/settings/assurances" id="tree-programs-caep-assurances"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/caep/settings/dates-messaging" id="tree-programs-caep-dates-messaging"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Allocations" itemUrl="/caep/settings/allocations" id="tree-programs-caep-allocations"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Guidance" itemUrl="/caep/settings/guidance" id="tree-programs-caep-guidance"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Budget & Reporting" itemUrl="/caep/settings/budget-reporting" id="tree-programs-caep-budget-reporting"></po-nav-item>
                      <po-nav-item poTreeNode itemText="CC EC 84914" itemUrl="/caep/settings/cc-ec-84914" id="tree-programs-caep-cc-ec-84914"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Custom Surveys" itemUrl="/caep/settings/surveys" id="tree-programs-caep-custom-surveys"></po-nav-item>
                    </po-nav-group>
                  </ng-container>

                  <ng-container *ngSwitchCase="'ewd'">
                    <po-nav-item poTreeNode itemText="EWD" itemUrl="/ewd" [exactUrl]="true" id="tree-programs-ewd" class="site-nav__section-heading"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Applications" itemUrl="/ewd/applications" id="tree-programs-ewd-applications" iconId="applications"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('review:view', selectedProgram.id)" poTreeNode itemText="Application Reviews" itemUrl="/ewd/reviews" id="tree-programs-ewd-reviews" iconId="reviews"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('offer:edit', selectedProgram.id)" poTreeNode itemText="Application Funding" itemUrl="/ewd/funding" id="tree-programs-ewd-funding" iconId="funding"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('renewal:edit', selectedProgram.id)" poTreeNode itemText="Renewal Funding" itemUrl="/ewd/renewal" id="tree-programs-ewd-renewal" iconId="money-circle-arrow"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Plans" itemUrl="/ewd/plans" id="tree-programs-ewd-plans" iconId="plans"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Fiscal Reporting" itemUrl="/ewd/fiscal-reports" id="tree-programs-ewd-reporting" iconId="fiscal-reporting"></po-nav-item>
                    <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/ewd/settings" id="tree-programs-ewd-settings" iconId="gear">
                      <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/ewd/settings/details" id="tree-programs-ewd-details"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/ewd/settings/dates-messaging" id="tree-programs-ewd-dates-messaging"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Grants" itemUrl="/ewd/settings/grants" id="tree-programs-ewd-grants"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Allocations" itemUrl="/ewd/settings/allocations" id="tree-programs-ewd-allocations"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Custom Surveys" itemUrl="/ewd/settings/surveys" id="tree-programs-ewd-custom-surveys"></po-nav-item>
                    </po-nav-group>
                  </ng-container>

                  <ng-container *ngSwitchCase="'gp'">
                    <po-nav-item poTreeNode itemText="Guided Pathways" itemUrl="/gp" [exactUrl]="true" id="tree-programs-gp" class="site-nav__section-heading"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Projects" itemUrl="/gp/proposals" id="tree-programs-gp-proposals" iconId="plans"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Fiscal Reporting" itemUrl="/gp/fiscal-reports" id="tree-programs-gp-reporting" iconId="fiscal-reporting"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Analytics" itemUrl="/gp/analytics" id="tree-programs-gp-analytics" iconId="line-chart"></po-nav-item>
                    <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/gp/settings" id="tree-programs-gp-settings" iconId="gear">
                      <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/gp/settings/details" id="tree-programs-gp-details"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/gp/settings/dates-messaging" id="tree-programs-gp-dates-messaging"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Allocations" itemUrl="/gp/settings/allocations" id="tree-programs-gp-allocations"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Custom Surveys" itemUrl="/gp/settings/surveys" id="tree-programs-gp-custom-surveys"></po-nav-item>
                    </po-nav-group>
                  </ng-container>

                  <ng-container *ngSwitchCase="'iplan'">
                    <po-nav-item poTreeNode itemText="Integrated Plan" itemUrl="/iplan" [exactUrl]="true" id="tree-programs-iplan" class="site-nav__section-heading"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Projects" itemUrl="/iplan/proposals" id="tree-programs-iplan-proposals" iconId="plans"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Fiscal Reporting" itemUrl="/iplan/fiscal-reports" id="tree-programs-iplan-reporting" iconId="fiscal-reporting"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Analytics" itemUrl="/iplan/analytics" id="tree-programs-iplan-analytics" iconId="line-chart"></po-nav-item>
                    <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/iplan/settings" id="tree-programs-iplan-settings" iconId="gear">
                      <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/iplan/settings/details" id="tree-programs-iplan-details"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/iplan/settings/dates-messaging" id="tree-programs-iplan-dates-messaging"></po-nav-item>
                    </po-nav-group>
                  </ng-container>

                  <ng-container *ngSwitchCase="'swpk'">
                    <po-nav-item poTreeNode itemText="K12 SWP" itemUrl="/swpk" [exactUrl]="true" id="tree-programs-swpk" class="site-nav__section-heading"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Applications" itemUrl="/swpk/applications" id="tree-programs-swpk-applications" iconId="applications"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('review:view', selectedProgram.id)" poTreeNode itemText="Application Reviews" itemUrl="/swpk/reviews" id="tree-programs-swpk-reviews" iconId="reviews"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('offer:view', selectedProgram.id)" poTreeNode itemText="Application Funding" itemUrl="/swpk/funding" id="tree-programs-swpk-funding" iconId="funding"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Plans" itemUrl="/swpk/plans" id="tree-programs-swpk-plans" iconId="plans"></po-nav-item>
                    <po-nav-item poTreeNote itemText="Fiscal Reporting" itemUrl="/swpk/fiscal-reports/plans" id="tree-programs-swpk-reporting" iconId="fiscal-reporting"></po-nav-item>
                    <po-nav-group poTreeNode itemText="TAPs & Coordinators" id="tree-programs-taps-coordinators" iconId="people">
                      <po-nav-item poTreeNode itemText="Plans" itemUrl="/swpk/tap-pc/plans" id="tree-programs-tap_pc-plans" iconId="plans"></po-nav-item>
                      <po-nav-item poTreeNote itemText="Fiscal Reporting" itemUrl="/swpk/fiscal-reports/tap-pc" id="tree-programs-tap-pc-reporting" iconId="fiscal-reporting"></po-nav-item>
                    </po-nav-group>
                    <po-nav-item *ngIf="routeAccess('deferral:view', selectedProgram.id)" poTreeNode itemText="Funding Deferral" itemUrl="/swpk/deferral" id="tree-programs-swpk-deferral" iconId="calendar-money"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Analytics" itemUrl="/swpk/analytics" id="tree-programs-swpk-analytics" iconId="line-chart"></po-nav-item>
                    <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/swpk/settings" id="tree-programs-swpk-settings" iconId="gear">
                      <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/swpk/settings/details" id="tree-programs-swpk-details"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/swpk/settings/dates-messaging" id="tree-programs-swpk-dates-messaging"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Grants" itemUrl="/swpk/settings/grants" id="tree-programs-swpk-grants"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Allocations" itemUrl="/swpk/settings/allocations" id="tree-programs-swpk-allocations"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Custom Surveys" itemUrl="/swpk/settings/surveys" id="tree-programs-swpk-custom-surveys"></po-nav-item>
                    </po-nav-group>
                  </ng-container>

                  <ng-container *ngSwitchCase="'lvg'">
                    <po-nav-item poTreeNode itemText="Local Vision Goals" itemUrl="/lvg" [exactUrl]="true" id="tree-programs-lvg" class="site-nav__section-heading"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Goal Setting" itemUrl="/lvg/goals" id="tree-programs-lvg-goals" iconId="target-arrow"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Analytics" itemUrl="/lvg/analytics" id="tree-programs-lvg-analytics" iconId="line-chart"></po-nav-item>
                    <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/lvg/settings" id="tree-programs-lvg-settings" iconId="gear">
                      <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/lvg/settings/details" id="tree-programs-lvg-details"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/lvg/settings/dates-messaging" id="tree-programs-lvg-dates-messaging"></po-nav-item>
                    </po-nav-group>
                  </ng-container>

                  <ng-container *ngSwitchCase="'nep'">
                    <po-nav-item poTreeNode itemText="Nursing" itemUrl="/nep" [exactUrl]="true" id="tree-programs-nep" class="site-nav__section-heading"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Plans" itemUrl="/nep/plans" id="tree-programs-nep-plans" iconId="plans"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Fiscal Reporting" itemUrl="/nep/fiscal-reports" id="tree-programs-nep-reporting" iconId="fiscal-reporting"></po-nav-item>
                    <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/nep/settings" id="tree-programs-nep-settings" iconId="gear">
                      <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/nep/settings/details" id="tree-programs-nep-details"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/nep/settings/dates-messaging" id="tree-programs-nep-dates-messaging"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Allocations" itemUrl="/nep/settings/allocations" id="tree-programs-nep-allocations"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Custom Surveys" itemUrl="/nep/settings/surveys" id="tree-programs-nep-custom-surveys"></po-nav-item>
                    </po-nav-group>
                  </ng-container>

                  <ng-container *ngSwitchCase="'perkins'">
                    <po-nav-item poTreeNode itemText="Perkins" itemUrl="/perkins" [exactUrl]="true" id="tree-programs-perkins" class="site-nav__section-heading"></po-nav-item>
                    <po-nav-group poTreeNode itemText="Title 1C" itemUrl="/perkins/perkins-1c" id="tree-programs-perkins-1c" iconId="layers">
                      <po-nav-item poTreeNode itemText="Headcount" itemUrl="/perkins/perkins-1c/headcount" id="tree-programs-perkins-1c-headcount" iconId="person-numbers"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Applications" itemUrl="/perkins/perkins-1c/applications" id="tree-programs-perkins-1c-applications" iconId="applications"></po-nav-item>
                      <po-nav-item poTreeNote itemText="Fiscal Reporting" itemUrl="/perkins/perkins-1c/fiscal-reports" id="tree-programs-perkins-1c-reporting" iconId="fiscal-reporting"></po-nav-item>
                    </po-nav-group>
                    <po-nav-group poTreeNode itemText="Title 1B" itemUrl="/perkins/perkins-1b" id="tree-programs-perkins-1b" iconId="layers">
                      <po-nav-item poTreeNode itemText="Applications" itemUrl="/perkins/perkins-1b/applications" id="tree-programs-perkins-1b-applications" iconId="applications"></po-nav-item>
                      <po-nav-item *ngIf="routeAccess('review:view', selectedProgram.id)" poTreeNode itemText="Application Reviews" itemUrl="/perkins/perkins-1b/reviews" id="tree-programs-perkins-1b-reviews" iconId="reviews"></po-nav-item>
                      <po-nav-item *ngIf="routeAccess('offer:edit', selectedProgram.id)" poTreeNode itemText="Application Funding" itemUrl="/perkins/perkins-1b/funding" id="tree-programs-perkins-1b-funding" iconId="funding"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Plans" itemUrl="/perkins/perkins-1b/plans" id="tree-programs-perkins-1b-plans" iconId="plans"></po-nav-item>
                      <po-nav-item poTreeNote itemText="Fiscal Reporting" itemUrl="/perkins/perkins-1b/fiscal-reports" id="tree-programs-perkins-1b-reporting" iconId="fiscal-reporting"></po-nav-item>
                    </po-nav-group>
                    <po-nav-group poTreeNode itemText="Reserve" itemUrl="/perkins/perkins-reserve" id="tree-programs-perkins-reserve" iconId="layers">
                      <po-nav-item poTreeNode itemText="Applications" itemUrl="/perkins/perkins-reserve/applications" id="tree-programs-perkins-reserve-applications" iconId="applications"></po-nav-item>
                      <po-nav-item *ngIf="routeAccess('review:view', selectedProgram.id)" poTreeNode itemText="Application Reviews" itemUrl="/perkins/perkins-reserve/reviews" id="tree-programs-perkins-reserve-reviews" iconId="reviews"></po-nav-item>
                      <po-nav-item *ngIf="routeAccess('offer:edit', selectedProgram.id)" poTreeNode itemText="Application Funding" itemUrl="/perkins/perkins-reserve/funding" id="tree-programs-perkins-reserve-funding" iconId="funding"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Plans" itemUrl="/perkins/perkins-reserve/plans" id="tree-programs-perkins-reserve-plans" iconId="plans"></po-nav-item>
                      <po-nav-item poTreeNote itemText="Fiscal Reporting" itemUrl="/perkins/perkins-reserve/fiscal-reports" id="tree-programs-perkins-reserve-reporting" iconId="fiscal-reporting"></po-nav-item>
                    </po-nav-group>
                    <po-nav-group poTreeNode itemText="Non-Trad Careers & Training" itemUrl="/perkins/perkins-ntct" id="tree-programs-ntct-reserve" iconId="layers">
                      <po-nav-item poTreeNode itemText="Applications" itemUrl="/perkins/perkins-ntct/applications" id="tree-programs-perkins-ntct-applications" iconId="applications"></po-nav-item>
                      <po-nav-item *ngIf="routeAccess('review:view', selectedProgram.id)" poTreeNode itemText="Application Reviews" itemUrl="/perkins/perkins-ntct/reviews" id="tree-programs-perkins-ntct-reviews" iconId="reviews"></po-nav-item>
                      <po-nav-item *ngIf="routeAccess('offer:edit', selectedProgram.id)" poTreeNode itemText="Application Funding" itemUrl="/perkins/perkins-ntct/funding" id="tree-programs-perkins-ntct-funding" iconId="funding"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Plans" itemUrl="/perkins/perkins-ntct/plans" id="tree-programs-perkins-ntct-plans" iconId="plans"></po-nav-item>
                      <po-nav-item poTreeNote itemText="Fiscal Reporting" itemUrl="/perkins/perkins-ntct/fiscal-reports" id="tree-programs-perkins-ntct-reporting" iconId="fiscal-reporting"></po-nav-item>
                    </po-nav-group>
                    <po-nav-item *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Invoicing" itemUrl="/perkins/invoicing" id="tree-programs-perkins-invoicing" iconId="invoicing"></po-nav-item>
                    <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/perkins/settings" id="tree-programs-perkins-settings" iconId="gear">
                      <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/perkins/settings/details" id="tree-programs-perkins-details"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/perkins/settings/dates-messaging" id="tree-programs-perkins-dates-messaging"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Grants" itemUrl="/perkins/settings/grants" id="tree-programs-perkins-grants"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Allocations" itemUrl="/perkins/settings/allocations" id="tree-programs-perkins-allocations"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Custom Surveys" itemUrl="/perkins/settings/surveys" id="tree-programs-perkins-custom-surveys"></po-nav-item>
                    </po-nav-group>
                  </ng-container>

                  <ng-container *ngSwitchCase="'rcm'">
                    <po-nav-item poTreeNode itemText="RC Management" itemUrl="/rcm" [exactUrl]="true" id="tree-programs-rcm" class="site-nav__section-heading"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Applications" itemUrl="/rcm/applications" id="tree-programs-rcm-applications" iconId="applications"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('review:view', selectedProgram.id)" poTreeNode itemText="Application Reviews" itemUrl="/rcm/reviews" id="tree-programs-rcm-reviews" iconId="reviews"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('offer:view', selectedProgram.id)" poTreeNode itemText="Application Funding" itemUrl="/rcm/funding" id="tree-programs-rcm-funding" iconId="funding"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Plans" itemUrl="/rcm/plans" id="tree-programs-rcm-plans" iconId="plans"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Fiscal Reporting" itemUrl="/rcm/fiscal-reports" id="tree-programs-rcm-reporting" iconId="fiscal-reporting"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('renewal:edit', selectedProgram.id)" poTreeNode itemText="Renewal Funding" itemUrl="/rcm/renewal" id="tree-programs-rcm-renewal" iconId="money-circle-arrow"></po-nav-item>
                    <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/rcm/settings" id="tree-programs-rcm-settings" iconId="gear">
                      <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/rcm/settings/details" id="tree-programs-rcm-details"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/rcm/settings/dates-messaging" id="tree-programs-rcm-dates-messaging"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Grants" itemUrl="/rcm/settings/grants" id="tree-programs-rcm-grants"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Allocations" itemUrl="/rcm/settings/allocations" id="tree-programs-rcm-allocations"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Custom Surveys" itemUrl="/rcm/settings/surveys" id="tree-programs-rcm-custom-surveys"></po-nav-item>
                    </po-nav-group>
                  </ng-container>

                  <ng-container *ngSwitchCase="'sep'">
                    <po-nav-item poTreeNode itemText="SEA Program" itemUrl="sep" [exactUrl]="true" id="tree-programs-sep" class="site-nav__section-heading"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Plans" itemUrl="/sep/plans" id="tree-programs-sep-plans" iconId="plans"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Reporting" itemUrl="/sep/reporting" id="tree-programs-sep-reports" iconId="clipboard-data"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Analytics" itemUrl="/sep/analytics" id="tree-programs-sep-analytics" iconId="line-chart"></po-nav-item>
                    <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/sep/settings" id="tree-programs-sep-settings" iconId="gear">
                      <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/sep/settings/details" id="tree-programs-sep-details"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/sep/settings/dates-messaging" id="tree-programs-sep-dates-messaging"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Grants" itemUrl="/sep/settings/grants" id="tree-programs-sep-grants"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Allocations" itemUrl="/sep/settings/allocations" id="tree-programs-sep-allocations"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Custom Surveys" itemUrl="/sep/settings/surveys" id="tree-programs-sep-custom-surveys"></po-nav-item>
                    </po-nav-group>
                  </ng-container>

                  <ng-container *ngSwitchCase="'swpl'">
                    <po-nav-item poTreeNode itemText="SWP - Local" itemUrl="swpl" [exactUrl]="true" id="tree-programs-swpl" class="site-nav__section-heading"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Plans" itemUrl="/swpl/proposals" id="tree-programs-swpl-proposals" iconId="plans"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Fiscal Reporting" itemUrl="/swpl/fiscal-reports" id="tree-programs-swpl-reporting" iconId="fiscal-reporting"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('analytics:view', selectedProgram.id)"  poTreeNode itemText="Analytics" itemUrl="/swpl/analytics" id="tree-programs-swpl-analytics" iconId="line-chart"></po-nav-item>
                    <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/swpl/settings" id="tree-programs-swpl-settings" iconId="gear">
                      <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/swpl/settings/details" id="tree-programs-swpl-details"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Assurances" [itemUrl]="settingsRoutes['swpl-assurances']" id="tree-programs-swpl-assurances"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/swpl/settings/dates-messaging" id="tree-programs-swpl-dates-messaging"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Allocations" itemUrl="/swpl/settings/allocations" id="tree-programs-swpl-allocations"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Guidance" itemUrl="/swpl/settings/guidance" id="tree-programs-swpl-guidance"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Custom Surveys" itemUrl="/swpl/settings/surveys" id="tree-programs-swpl-custom-surveys"></po-nav-item>
                    </po-nav-group>
                  </ng-container>

                  <ng-container *ngSwitchCase="'swpr'">
                    <po-nav-item poTreeNode itemText="SWP - Regional" itemUrl="swpr" [exactUrl]="true" id="tree-programs-swpr" class="site-nav__section-heading"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Plans" itemUrl="/swpr/proposals" id="tree-programs-swpr-proposals" iconId="plans"></po-nav-item>
                    <po-nav-item poTreeNode itemText="Fiscal Reporting" itemUrl="/swpr/fiscal-reports" id="tree-programs-swpr-reporting" iconId="fiscal-reporting"></po-nav-item>
                    <po-nav-item *ngIf="routeAccess('analytics:view', selectedProgram.id)" poTreeNode itemText="Analytics" itemUrl="/swpr/analytics" id="tree-programs-swpr-analytics" iconId="line-chart"></po-nav-item>
                    <po-nav-group *ngIf="routeAccess('fund-settings:view', selectedProgram.id)" poTreeNode itemText="Program Settings" itemUrl="/swpr/settings" id="tree-programs-swpr-settings" iconId="gear">
                      <po-nav-item poTreeNode itemText="Details & Roles" itemUrl="/swpr/settings/details" id="tree-programs-swpr-details"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Assurances" [itemUrl]="settingsRoutes['swpr-assurances']" id="tree-programs-swpr-assurances"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Dates & Messaging" itemUrl="/swpr/settings/dates-messaging" id="tree-programs-swpr-dates-messaging"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Allocations" itemUrl="/swpr/settings/allocations" id="tree-programs-swpr-allocations"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Guidance" itemUrl="/swpr/settings/guidance" id="tree-programs-swpr-guidance"></po-nav-item>
                      <po-nav-item poTreeNode itemText="Custom Surveys" itemUrl="/swpr/settings/surveys" id="tree-programs-swpr-custom-surveys"></po-nav-item>
                    </po-nav-group>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>

          <po-nav-group *ngIf="routeAccess('user-admin')" poTreeNode itemText="Admin" itemUrl="/admin" id="tree-admin" iconId="avatar-shield" class="site-nav--ruled site-nav--shadowed">
            <po-nav-item poTreeNode itemText="User Management" itemUrl="/admin/users" id="tree-admin-users"></po-nav-item>
            <po-nav-item poTreeNode itemText="Communications" itemUrl="/admin/communications" id="tree-admin-communications"></po-nav-item>
            <po-nav-item poTreeNode itemText="Roles" itemUrl="/admin/roles" id="tree-admin-roles"></po-nav-item>
            <po-nav-item *ngIf="routeAccess('program-admin')" poTreeNode itemText="Program Management" itemUrl="/admin/program-management" id="tree-admin-program-managment"></po-nav-item>
          </po-nav-group>
          <po-nav-item *ngIf="routeAccess('fiscal-agent-hub')" poTreeNode itemText="Fiscal Agent Hub" itemUrl="/fiscal-agents" id="fiscal-agent-hub" iconId="money-circle"></po-nav-item>
          <po-nav-item *ngIf="routeAccess('all-programs')" poTreeNode itemText="Institutions" itemUrl="/institutions" id="tree-institutions" iconId="building"></po-nav-item>
          <po-nav-item poTreeNode itemText="Help" itemUrl="/help" id="tree-help" iconId="help--fill" class="site-nav--ruled"></po-nav-item>

        </po-nav-tree>
      </div>
    </div>
  </ng-container>
</nav>
